import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { PasswordRequirementsDialogComponent } from '../common/password-requirements-dialog/password-requirements-dialog.component';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.css']
})
export class PasswordChangeComponent {

  public passwordChangeForm: UntypedFormGroup = this.buildPasswordChangeForm();
  public formSubmitted: boolean = false;
  public passwordChangeRequestFailed: boolean = false;
  public isSpinnerVisible: boolean = false;

  public get passwordControls() {
    return this.passwordChangeForm?.controls;
  }

  public get currentUserEmailAddress() {
    return this.userDataService.currentUserEmail ?? '';
  }

  constructor(
    private userDataService: UserDataService,
    private dialog: MatDialog,
    private navigator: NavigatorService,
    private formBuilder: UntypedFormBuilder,
    private formValidation: FormValidationService)
  {
    this.passwordChangeForm = this.buildPasswordChangeForm();
  }

  public async confirm() : Promise<void> {
    this.formSubmitted = true;
    if (!this.passwordChangeForm.invalid) {
      await this.performPasswordChange();
    }
  }

  public openPasswordRequirementsDialog() : void {
    this.dialog.open(PasswordRequirementsDialogComponent);
  }

  private buildPasswordChangeForm() : UntypedFormGroup {
    return this.formBuilder.group({
      password: ['', this.formValidation.passwordValidator()],
      password2: ['', this.formValidation.passwordValidator()]
    }, { validators: this.formValidation.passwordMatchValidator('password', 'password2')});
  }

  private async performPasswordChange() : Promise<void> {
    this.isSpinnerVisible = true;
    const passwordChangeResult = await this.userDataService
      .changeCurrentUserPassword(this.passwordControls.password.value);
    this.isSpinnerVisible = false;
    if (passwordChangeResult) {
      this.redirectSuccessfullPasswordChange();
    } else {
      this.setPasswordDidNotChangeInfo()
    }
  }

  private setPasswordDidNotChangeInfo() : void {
    this.passwordControls.password.patchValue('');
    this.passwordControls.password2.patchValue('');
    this.formSubmitted = false;
    this.passwordChangeRequestFailed = true;
    setTimeout(() => {
      this.passwordChangeRequestFailed = false;
    }, 5000);
  }

  private redirectSuccessfullPasswordChange() : void {
    this.navigator.redirectToBenefitsScreen();
  }
}
