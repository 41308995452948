import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'report-presenter',
  templateUrl: './report-presenter.component.html',
  styleUrls: ['./report-presenter.component.css']
})
export class ReportPresenterComponent {

  @Input() reportTitle: string = '';
  @Input() reportDescription: string = '';
  @Output() generateReport = new EventEmitter();

  public handleGenerateReportButtonClick() : void {
    this.generateReport.emit();
  }
}
