import { Injectable } from '@angular/core';
import { ExcelReaderService } from './excel-reader.service';
import { ScoringService } from '../services/scoring.service';

@Injectable({
  providedIn: 'root'
})
export class ScoringLoaderService {

  constructor(
    private excelReader: ExcelReaderService,
    private scoringService: ScoringService
  ) { }

  public async executeScoringReplacement(excelFile: any) : Promise<boolean> {
    try {
      const scoringData = await this.excelReader.parseExcelFile(excelFile);
      const validScoring = this.collectValidScoringData(scoringData);
      if (validScoring.length > 0) {
        await this.scoringService.clearScoringData();
        const successesAmount = await this.scoringService.addScoringToStorage(validScoring);
        return validScoring.length === scoringData.length &&
        successesAmount === validScoring.length;
      } else {
        return true;
      }
    } catch (e) {
      console.error(e);
      console.error('Could not load new benefits data');
      return false;
    }
  }

  private collectValidScoringData(excelScoringData: Array<any>) : Array<any> {
    const validScoring: Array<any> = [];
    excelScoringData.forEach(sd => {
      const parsedScore = this.createScoreRecordObject(sd);
      if (this.validateSingleScoreRecord(parsedScore)) {
        validScoring.push(parsedScore);
      }
    });
    return validScoring;
  }

  private createScoreRecordObject(scoreExcelData: any) : any {
    const scoreData: any = {
      productName: scoreExcelData.productName,
      score: scoreExcelData.score
    };
    return scoreData;
  }

  private validateSingleScoreRecord(scoreRecord: any) : boolean {
    if (scoreRecord?.productName?.length > 0 &&
      scoreRecord?.score) {
      return true;
    }
    console.warn('Invalid score data.');
    console.warn(scoreRecord);
    return false;
  }
}