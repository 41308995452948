<div class="application-view-main-container">
  <div class="application-view-title">Ustawienia akcji promocyjnej</div>
  <div class="main-container">
    <form [formGroup]="campaignForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <span>Kampania promocyjna aktywna:</span>
        <label class="switch">
          <input type="checkbox" formControlName="campaignActive" (change)="toggleStartCampaignSwitch()">
          <span class="slider round"></span>
        </label>
      </div>
      <div class="form-group">
        <span>Wyświetl komunikat dla użytkowników:</span>
        <label class="switch">
          <input type="checkbox" formControlName="usersMessageActive" (change)="toggleShowMessageForUsersSwitch()">
          <span class="slider round"></span>
        </label>
      </div>
      <div>
        <label for="userMessage">Treść komunikatu dla użytkowników:</label>
        <textarea id="userMessage" formControlName="userMessage"></textarea>
      </div>
      <div class="form-group">
        <span>Oblicz ranking:</span>
        <button type="button" (click)="countRanking()">Oblicz</button>
        <!-- <label class="switch">
          <input type="checkbox" formControlName="usersMessageActive" (change)="toggleShowMessageForUsersSwitch()">
          <span class="slider round"></span>
        </label> -->
      </div>
      <!-- <div class="form-group">
        <span>Aktywacja przelicznka:</span>
        <label class="switch">
          <input type="checkbox" formControlName="conversionFactorEdit" (change)="toggleConversionFactorEdit()">
          <span class="slider round"></span>
        </label>
      </div>
      <div class="form-group">
        <span>Przelicznik punkty do PLN:</span>
        <label for="conversionFactor">
          <input type="number" formControlName="conversionFactor">
        </label>
      </div> -->
      <button type="submit">Zapisz ustawienia</button>
    </form>
  </div>
  <div class="return-button-container">
    <button type="button" class="btn btn-sm btn-secondary btn-secondary-custom"
      (click)="redirectToAdministrationScreen()">Wróć</button>
  </div>
</div>
<waiting-spinner *ngIf="isSpinnerVisible" [text]="'Przetwarzanie...'"></waiting-spinner>