<div class="application-view-main-container">
  <div *ngIf="treatUserAsLogger; else userNotLogged" style="padding-top: 3rem !important;">
    <p class="landing-page-text landing-page-text-big text-orange text-bold">Witaj na platformie promocyjnej Panasonic.</p>
    <!-- <p class="landing-page-text text-green">W podziękowaniu przygotowaliśmy <span
        class="text-orange text-bold">Panasonic Store</span>, miejsce w którym wybierzesz i zamówisz <b>nagrody
        najlepsze dla Ciebie</b>!</p> -->
    <p class="landing-page-text text-black" style="padding-top: 0.5rem; padding-bottom: 0.5rem; font-weight: 500;">
      Wspieramy i nagradzamy profesjonalistów w podziękowaniu za zaufanie i współpracę.</p>
    <p class="landing-page-text text-black">Tutaj w zakładce <b>UŻYTKOWNIK</b> możesz śledzić swoje aktualne wyniki i listę zarejestrowanych urządzeń.</p>
    <p class="landing-page-text text-black">Tutaj też po zakończeniu promocji wybierzesz i zamówisz <b>NAGRODY</b> według poniższej procedury:</p>
    <div class="black-text-container" style="padding-left: 30px;">
      <p class="landing-page-text" style="font-size: 1.2em;">- wejdź do zakładki <b>NAGRODY</b>,</p>
      <p class="landing-page-text" style="font-size: 1.2em;">- komunikat <b>STAN KONTA</b> pokazuje ilość zgromadzonych środków PLN do wydania,</p>
      <p class="landing-page-text" style="font-size: 1.2em;">- kliknij wybraną kartę podarunkową,</p>
      <p class="landing-page-text" style="font-size: 1.2em;">- wybierz wartość i ilość kart podarunkowych, następnie kliknij <b>DODAJ DO KOSZYKA</b>,</p>
      <p class="landing-page-text" style="font-size: 1.2em;">- wróć do zakładki <b>NAGRODY</b> aby wybrać kolejne karty podarunkowe,</p>
      <p class="landing-page-text" style="font-size: 1.2em;">- kontroluj <b>STAN KONTA</b>, wybieraj aż do wyczerpania wszystkich środków,</p>
      <p class="landing-page-text" style="font-size: 1.2em;">- w zakładce <b>KOSZYK</b> sprawdź swoje zamówienie, możesz jeszcze wprowadzać zmiany,</p>
      <p class="landing-page-text" style="font-size: 1.2em;">- jeśli jesteś pewien wyborów kliknij <b>ZŁÓŻ ZAMÓWIENIE</b>, otrzymasz potwierdzenie mailowe;</p>
      <!-- <p class="landing-page-text text-orange text-link" (click)="redirectToBenefits()"> -->
    </div>
    <p class="landing-page-text text-centered">Życzymy dobrej zabawy!</p>
    <!-- <p class="landing-page-text text-link" (click)="redirectToBenefits()">
      <span>Przejdź do katalogu nagród </span>
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-right"
          viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
        </svg>
      </span>
    </p> -->
  </div>
  <ng-template #userNotLogged>
    <div class="texts-container">
      <p class="landing-page-text-big text-bold">
        <span class="text-black">Witaj na</span>
        <span class="text-orange"> platformie promocyjnej </span><br>
        <span class="text-black">Panasonic!</span>
      </p>
      <p class="landing-page-text text-black">Wspieramy i nagradzamy profesjonalistów.</p>
      <button type="button" class="btn btn-primary btn-primary-custom" (click)="redirectToLogin()">Zaloguj się i aktywuj swoje konto</button>


      <!-- <p class="landing-page-text text-green text-centered text-link" (click)="redirectToLogin()">Zaloguj się i aktywuj swoje konto</p>
      <p class="landing-page-text text-green text-centered text-link">Twój login to adres email z Panasonic PRO Club</p> -->
    </div>
  </ng-template>
</div>