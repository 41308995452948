import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MongoService } from 'src/app/services/mongo.service';
import { NavigatorService } from 'src/app/services/navigator.service';

@Component({
  selector: 'users-management-view',
  templateUrl: './users-management-view.component.html',
  styleUrl: './users-management-view.component.css'
})
export class UsersManagementViewComponent implements OnInit {

  data: any;
  displayedItems: Array<any> = [];
  currentFilterValue: string = "";
  isSpinnerVisible:boolean = false;

  constructor(
    public mongoService: MongoService,
    private changeDetector: ChangeDetectorRef,
    private navigatorService: NavigatorService,
    private navigator: NavigatorService
  ){}

  async ngOnInit(): Promise<void> {
    this.isSpinnerVisible = true;
    this.data = await this.mongoService.getAllUsersData();
    this.isSpinnerVisible = false;
  }

  filterValueChanged(event: any): void{
    this.currentFilterValue = event?.target?.value.toLowerCase();
  }

  updateDisplayedList(items: Array<any>): void{
    this.displayedItems = items;
    this.changeDetector.detectChanges();
  }

  openItem(selectedUser : any){
    this.navigatorService.redirectToUserEditScreen(selectedUser.userEmail);
  }

  public redirectToAdministrationScreen() : void {
    this.navigator.redirectToAdministrationScreen();
  }
}
