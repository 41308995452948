import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BenefitsService } from 'src/app/services/benefits.service';
import { CartService } from 'src/app/services/cart.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { PromoCampaignService } from 'src/app/services/promo-campaign.service';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-view-benefit-details',
  templateUrl: './view-benefit-details.component.html',
  styleUrls: ['./view-benefit-details.component.css']
})
export class ViewBenefitDetailsComponent implements OnInit {
  private _benefitData: any;
  public selectedPrice: number | null = null;
  public selectedAmount: number = 1;

  public get benefitData() {
    return this._benefitData;
  }

  public get isBenefitDataAvailable() {
    return this._benefitData !== null && this._benefitData !== undefined;
  }

  public get availablePrices() {
    return this._benefitData?.availablePrices ?? [];
  }

  public get userPointsAmount () {
    return this.cartService?.userAvailablePoints ?? 0;
  }

  public get didUserPlaceOrder() {
    return this.userDataService.didUserPlacedOrder;
  }

  public get canAddToCart() {
    if (this.userDataService.didUserPlacedOrder) {
      return false;
    }
    if (!this.selectedPrice) {
      return false;
    }
    return this.cartService.userAvailablePoints - (this.selectedPrice * this.selectedAmount) >= 0;
  }

  public get maximumAmountToAddToCart() {
    const pointsLeft: number = this.cartService.userAvailablePoints;
    if (this.selectedPrice && this.selectedPrice > 0) {
      return Math.floor(pointsLeft/this.selectedPrice);
    }
    return 1;
  }

  public get benefitDescription() {
    return this.benefitData?.description ?? '';
  }

  public get hasHyperlink() {
    return this._benefitData?.hyperlink?.length > 0;
  }

  public get hyperlink() {
    if (this.hasHyperlink) {
      if (this.benefitData.hyperlink.includes('http')){
        return this.benefitData.hyperlink;
      }
      return `https://${this.benefitData.hyperlink}`;
    }
    return '';
  }

  constructor(private route: ActivatedRoute,
    private navigator: NavigatorService,
    private benefitsService: BenefitsService,
    private cartService: CartService,
    private userDataService: UserDataService,
    public promoCampaignService: PromoCampaignService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this._benefitData = await this.getSelectedBenefitData();
  }

  public redirectToBenefitsScreen() : void {
    this.navigator.redirectToBenefitsScreen();
  }

  public selectedPriceChange = () => {
    this.selectedAmount = 1;
  }

  public addToCart() : void {
    if (this.selectedPrice && this.selectedPrice > 0 && this.canAddToCart) {
      const price: number = typeof this.selectedPrice === "string" ? parseInt(this.selectedPrice) : this.selectedPrice;
      const amount: number = typeof this.selectedAmount === "string" ? parseInt(this.selectedAmount) : this.selectedAmount;
      if (price > 0 && amount > 0) {
        this.cartService.addItemToCart(this.benefitData, price, amount);
        this.selectedAmount = 1;
      }
    }
  }

  private async getSelectedBenefitData() : Promise<any> {
    const benefitId = this.obtainBenefitIdFromQueryParameter();
    const benefitsData = await this.benefitsService.getAllExistingBenefits();
    const benefit = benefitsData?.find(b => b._id === benefitId);
    if (benefit) {
      return benefit;
    }
    throw new Error("Couldn't find benefit data.");
  }

  private obtainBenefitIdFromQueryParameter() : string {
    let identity: string = '';
    this.route.queryParams
      .subscribe(parameters => {
        identity = parameters.id;
      });
    return identity;
  }
}
