import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Alert, AlertType } from './alert.model';
import { AlertService } from './alert.service';

@Component({ 
  selector: 'alert', 
  templateUrl: 'alert.component.html' 
})

export class AlertComponent implements OnInit, OnDestroy {
    @Input() fade = true;

    alerts: Alert[] = [];
    alertSubscription!: Subscription;
    alertDeleteSubscription!: Subscription;
    routeSubscription!: Subscription;

    constructor(private alertService: AlertService, private router : Router) {
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {        
                this.filterAfterRouteChange();
            };
          });
    }

    ngOnInit() {
        this.alertSubscription = this.alertService.newAlert$.subscribe(
            alert => {
                this.alerts.push(alert);
                if (alert.autoClose) {
                    setTimeout(() => this.removeAlert(alert), 3000);
                }
            },
            () => {
            }
        )

        this.alertDeleteSubscription = this.alertService.deleteAlert$.subscribe(
            alertAlias => {
                if (alertAlias)
                    this.removeAlertByAlias(alertAlias);
                else
                    this.removeAllAlerts();
            },
            () => {
            }
        )
    }

    ngOnDestroy() {
        this.alertSubscription.unsubscribe();
    }

    removeAlert(alert: Alert) {
        if (!this.alerts.includes(alert)) return;

        if (this.fade) {
            (this.alerts.find(x => x === alert) as Alert).fade = true;
            setTimeout(() => {
                this.alerts = this.alerts.filter(x => x !== alert);
            }, 250);
        } else {
            this.alerts = this.alerts.filter(x => x !== alert);
        }
    }

    removeAlertByAlias(alertAlias: string){
        this.alerts = this.alerts.filter(x => x.alias != alertAlias);
    }

    removeAllAlerts(){
        this.alerts = [];
    }

    filterAfterRouteChange(){
        this.alerts = this.alerts.filter(x => x.keepAfterRouteChange == true);
    }

    cssClass(alert: Alert) {
        if (!alert) return;

        const classes = ['alert', 'alert-dismissable'];
        //const classes = ['alert', 'alert-dismissable', 'customAlert'];
                
        const alertTypeClass = {
            [AlertType.Success]: 'alert succes-alert',
            [AlertType.Error]: 'alert error-alert',
            [AlertType.Info]: 'alert alert-info fade show', //JEZELI POTRZEBNE DODAC ODPOWIEDNIA KLASE W styles.css 
            [AlertType.Warning]: 'alert alert-warning'      //JEZELI POTRZEBNE DODAC ODPOWIEDNIA KLASE W styles.css 
        }

        classes.push(alertTypeClass[alert.type]);

        if (alert.fade) {
            classes.push('fade');
        }

        return classes.join(' ');
    }
}