import { Injectable } from '@angular/core';
import { User } from '@firebase/auth';
import { addDoc, collection, getDocs, query, where, deleteDoc, doc } from '@firebase/firestore';
import { ApiService } from './api.service';
import { CartService } from './cart.service';
import { FirebaseInitService } from './firebase-init.service';
import { UserAdditionalDataService } from './user-additional-data.service';
import { UserDataService } from './user-data.service';
import { MongoService } from './mongo.service';

@Injectable({
  providedIn: 'root'
})
export class UserOrdersService {

  private userOrders: Array<any> = [];

  public get didUserPlaceOrder() {
    return this.userOrders?.length > 0 ?? false;
  }

  constructor(
    private firebaseService: FirebaseInitService, 
    private apiService: ApiService,
    private mongoService: MongoService
    ) {    
  }

  public async checkIfUserPlacedAnyOrder(currentUser: User) : Promise<boolean> {
    if (currentUser) {
      this.userOrders = await this.getUserOrders(currentUser);
      return this.userOrders?.length > 0;
    }
    return false;
  }

  public async placeUserOrder(cartService: CartService, currentUser: User, userDataService: UserDataService,
    userAdditionalDataService: UserAdditionalDataService): Promise<boolean> {
    if (currentUser && !this.didUserPlaceOrder) {
      const orderData = this.generateOrderObject(cartService, currentUser);
      const emailTemplateData = await this.generateConfirmationEmailTemplateData(cartService, orderData, userDataService);
      if (orderData) {        
        const orderAddResult = await this.addOrderToStorage(orderData);
        if (orderAddResult) {
          if (await this.checkIfUserPlacedAnyOrder(currentUser))
            this.apiService.sendOrderConfirmationEmail(emailTemplateData);
        }
        return orderAddResult;
      }
    }
    return false;
  }

  public async getAllExistingOrders() : Promise<Array<any>> {
    try {
      let orders: Array<any> = [];
      orders = await this.mongoService.getUsersOrders();
      return orders;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  public async clearOrdersData() : Promise<void> {
    const allOrders = await this.getAllExistingOrders();
    if (allOrders?.length > 0) {
      for (let i = 0; i < allOrders.length; i++)  {
        await deleteDoc(doc(this.firebaseService.database, 'userOrders', allOrders[i]._id));
      }
    }
  }

  private async addOrderToStorage(orderObject: any) : Promise<boolean> {
    try {
      await this.mongoService.addUserOrder(orderObject);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  private async generateConfirmationEmailTemplateData(cartService: CartService, orderData: any, userDataService: UserDataService){
    const usrAdditionalData = await userDataService.getUserDataByEmail(String(userDataService.currentUser?.email))
    const items: Array<any> = [];
    cartService?.cartItems?.forEach(i => {
      items.push({
        name: String(i.name).toString(),
        quantity: String(i.quantity).toString(),
        unitPrice: String(i.unitPrice).toString()
      });
    });
    return {
      userEmail: String(orderData.userEmail).toString(),
      firstName: String(usrAdditionalData.firstName).toString(),
      lastName: String(usrAdditionalData.lastName).toString(),
      streetName: String(usrAdditionalData.streetName).toString(),
      buildingNumber: String(usrAdditionalData.buildingNumber).toString(),
      postalCode: String(usrAdditionalData.postalCode).toString(),
      city: String(usrAdditionalData.city).toString(),
      voivodeship: String(usrAdditionalData.voivodeship).toString(),
      phoneNumber: String(usrAdditionalData.phoneNumber).toString(),
      totalPrice: String(cartService.getCartTotalPrice()).toString(),
      points: String(usrAdditionalData.prizeAmount).toString(),
      items: items
    }
  }

  private generateOrderObject(cartService: CartService, currentUser: User) : any {
    return {
      userEmail: currentUser?.email ?? '',
      userId: currentUser?.uid ?? '',
      priceSum: cartService.getCartTotalPrice(),
      orderDate: new Date(),
      items: this.flattenCartContent(cartService),
    }
  }

  private flattenCartContent(cartService: CartService) : Array<any> {
    const content: Array<any> = [];
    cartService?.cartItems?.forEach(i => {
      content.push({
        benefitId: i.benefitId,
        unitPrice: i.unitPrice,
        quantity: i.quantity
      });
    });
    return content;
  }

  private async getUserOrders(currentUser: User) : Promise<Array<any>> {
    try {
      return this.mongoService.getUserOrdersByEmail(currentUser.email ? currentUser.email : "");
    } catch (e) {
      console.log(e);
      return [];
    }
  }
}
