import { Component } from '@angular/core';
import { InfoDialogComponent } from '../../common/info-dialog/info-dialog.component';
import { NavigatorService } from 'src/app/services/navigator.service';
import { MailingService } from 'src/app/mailing/mailing.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'emails-view',
  templateUrl: './emails-view.component.html',
  styleUrl: './emails-view.component.css'
})
export class EmailsViewComponent {
  private _isSpinnerVisible: boolean = false;

  public sendMailingTitle: string = "Wysyłka powitalnego mailingu";
  public sendMailingText: string = 'By rozesłać mailing powitalny wczytaj listę adresów z pliku *xlsx. ' +
  'Mailing zużywa zasoby bramki email. ';
  private sendMailingMessage: string = 'Wiadomości email zostały wysłane.';
  private sendMailingErrorMessage: string = 'Wystąpił błąd podczas wysyłki wiadomości. ';


  public get isSpinnerVisible() {
    return this._isSpinnerVisible;
  }

  constructor(
    private navigator: NavigatorService,
    private mailingService: MailingService,
    private dialog: MatDialog) {}

  public redirectToAdministrationScreen() : void {
    this.navigator.redirectToAdministrationScreen();
  }

  public async sendMailing(excelFile: any) : Promise<void> {
    this._isSpinnerVisible = true;
    const sendMailingResult = await this.mailingService.sendHelloMailing(excelFile);
    this._isSpinnerVisible = false;
    if (sendMailingResult) {
      this.showSuccessDialog(this.sendMailingMessage);
    } else {
      this.showErrorDialog(this.sendMailingErrorMessage);
    }
  }

  private showSuccessDialog(message: string) : void {
    this.dialog.open(InfoDialogComponent, { disableClose: true, data: {
      title: 'Sukces',
      message: message,
      isErrorInfo: false,
      okFunc: null
    } });
  }

  private showErrorDialog(message: string) : void {
    this.dialog.open(InfoDialogComponent, { disableClose: true, data: {
      title: 'Błąd',
      message: message,
      isErrorInfo: true,
      okFunc: null
    } });
  }
}
