<div class="application-view-main-container">
    <div class="application-view-title">Wiadomości email</div>
    <div class="available-data-actions-list-container" style="margin-bottom: 1rem;">
        <email-presenter [actionTitle]="sendMailingTitle"
        [actionDescription]="sendMailingText"
        [useOpenExcel]="true"
        (executeAction)="sendMailing($event)"></email-presenter>
    </div>
    <div class="return-button-container">
      <button type="button" class="btn btn-sm btn-secondary btn-secondary-custom"
        (click)="redirectToAdministrationScreen()">Wróć</button>
    </div>
  </div>
  <waiting-spinner *ngIf="isSpinnerVisible" [text]="'Trwa wysyłanie wiadomości...'"></waiting-spinner>