import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { InfoDialogComponent } from 'src/app/components/common/info-dialog/info-dialog.component';
import { YesNoDialogComponent } from 'src/app/components/common/yes-no-dialog/yes-no-dialog.component';
import { MongoService } from 'src/app/services/mongo.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'user-edit-view',
  templateUrl: './user-edit-view.component.html',
  styleUrl: './user-edit-view.component.css'
})
export class UserEditViewComponent {
  isSpinnerVisible: boolean = false;
  userData: any;
  pointsTotal: number = 0;
  rankingData: any;

  constructor(
    private route: ActivatedRoute,
    private navigator: NavigatorService,
    private mongoService: MongoService,
    private dialog: MatDialog,
    public userDataService: UserDataService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isSpinnerVisible = true;
    this.userData = await this.getSelectedUserData();
    this.rankingData = await this.userDataService.getUserRankingAndCount(this.userData);
    this.countPointsTotal();
    this.isSpinnerVisible = false;
  }

  private async getSelectedUserData() : Promise<any> {
    const userEmail = this.obtainUserIdFromQueryParameter();
    const userData = await this.mongoService.getUserDataByEmail(userEmail);
    if (userData) {
      return userData;
    }
    throw new Error("Couldn't find userDat.");
  }

  private obtainUserIdFromQueryParameter() : string {
    let userEmail: string = '';
    this.route.queryParams
      .subscribe(parameters => {
        userEmail = parameters.userEmail;
      });
    return userEmail;
  }
  
  async executeSaveChanges() : Promise<void> {
    this.isSpinnerVisible = true;
    let result = await this.mongoService.updateUserData(this.userData);
    this.isSpinnerVisible = false;
    if(result.status == 200){
      this.showSuccessDialog();
    } else {
      this.showErrorDialog();
    }
  }

  private showSuccessDialog() : void {
    this.dialog.open(InfoDialogComponent, { disableClose: true, data: {
      title: 'Sukces',
      message: 'Zamiany zostaly zapisane.',
      isErrorInfo: false,
      okFunc: null
    } });
  }

  private showErrorDialog() : void {
    this.dialog.open(InfoDialogComponent, { disableClose: true, data: {
      title: 'Błąd',
      message: "Wystąpił błąd podczas zapisu. Jeżeli problem się powtarza skontaktuj się z administratorem",
      isErrorInfo: true,
      okFunc: null
    } });
  }

  public redirectToUsersScreen() : void {
    this.navigator.redirectToUsersManagementScreen();
  }

  countPointsTotal(){
    this.userData?.registeredProducts?.forEach((product: any) => {
      this.pointsTotal += product.score;
    });
  }
}
