<div class="login-form-container">
  <form [formGroup]="intialDataForm" (ngSubmit)="confirm()">
    <div class="login-form-items-container">
      <div class="login-form-title-container">
        <span>Rejestracja konta</span>
      </div>
      <div class="login-form-input-description-container">
        <span>Adres email</span>
        <input type="email" class="form-control" [value]="currentUserEmailAddress" disabled/>
      </div>
      <div class="login-form-input-description-container">
        <span>Imię</span>
        <input type="text" class="form-control" formControlName="firstName"/>
        <span *ngIf="formSubmitted && formControls.firstName.errors"
          class="input-error">Imię musi być podane</span>
      </div>
      <div class="login-form-input-description-container">
        <span>Nazwisko</span>
        <input type="text" class="form-control" formControlName="lastName"/>
        <span *ngIf="formSubmitted && formControls.lastName.errors"
          class="input-error">Nazwisko musi być podane</span>
      </div>
      <div class="login-form-input-description-container">
        <span>Nazwa firmy</span>
        <input type="text" class="form-control" formControlName="companyName"/>
        <span *ngIf="formSubmitted && formControls.companyName.errors"
          class="input-error">Nazwa firmy musi być podana</span>
      </div>
      <div class="login-form-input-description-container">
        <span>Numer telefonu</span>
        <input type="tel" class="form-control" formControlName="phoneNumber"/>
        <span *ngIf="formSubmitted && formControls.phoneNumber.errors"
          class="input-error">Numer telefonu musi być podany oraz mieć co najmniej 9 znaków</span>
      </div>
      <p style="padding: 0; margin: 0; margin-top: 1rem;">Adres wysyłki nagród.</p>
      <div class="login-form-input-description-container">
        <span>Ulica</span>
        <input type="text" class="form-control" formControlName="street"/>
        <span *ngIf="formSubmitted && formControls.street.errors"
          class="input-error">Nazwa ulicy musi być podana</span>
      </div>
      <div class="login-form-input-description-container">
        <span>Numer budynku/mieszkania</span>
        <input type="text" class="form-control" formControlName="number"/>
        <span *ngIf="formSubmitted && formControls.number.errors"
          class="input-error">Numer musi być podany</span>
      </div>
      <div class="login-form-input-description-container">
        <span>Kod pocztowy</span>
        <input type="text" class="form-control" formControlName="postalCode"/>
        <span *ngIf="formSubmitted && formControls.postalCode.errors"
          class="input-error">Kod pocztowy musi byc podany</span>
      </div>
      <div class="login-form-input-description-container">
        <span>Miasto</span>
        <input type="text" class="form-control" formControlName="city"/>
        <span *ngIf="formSubmitted && formControls.city.errors"
          class="input-error">Nazwa miasta musi być podana</span>
      </div>
      <div class="login-form-input-description-container">
        <span>Województwo</span>
        <input type="text" class="form-control" formControlName="voivodeship"/>
        <span *ngIf="formSubmitted && formControls.voivodeship.errors"
          class="input-error">Województwo musi być podane</span>
      </div>
      <div class="login-form-input-description-container">
        <span>Wprowadź nowe hasło</span>
        <input type="password" class="form-control" formControlName="password"/>
        <span *ngIf="formSubmitted && formControls.password.errors"
          class="input-error">Hasło musi być podane oraz musi spełniać wymagania</span>
      </div>
      <div class="login-form-input-description-container">
        <span>Powtórz nowe hasło</span>
        <input type="password" class="form-control" formControlName="password2"/>
        <span *ngIf="formSubmitted && formControls.password2.errors"
          class="input-error">Hasło musi być podane oraz musi spełniać wymagania</span>
      </div>
      <div class="login-form-input-description-container">
        <span id="pdfDownload" (click)="downloadPdf()">Pobierz regulamin promocji Aquarea</span>
      </div>
      <div class="login-form-input-description-container">
        <div class="checkbox-container">
          <span>Potwierdzam zapoznanie się z regulaminem</span>
          <input type="checkbox" value="false" (change)="toggleTermsCheckbox($event)">
        </div>
        <span *ngIf="!termsCheckbox"
        class="input-error">W celu rejestracji potwierdź zapoznanie się z regulaminem</span>
      </div>
      <div *ngIf="formSubmitted && intialDataForm.errors">
        <span class="input-error">Podane hasła nie są sobie równe</span>
      </div>
      <div class="password-requirements-button-container">
        <button type="button" class="btn btn-secondary btn-secondary-custom"
          (click)="openPasswordRequirementsDialog()">Wymagania hasła</button>
      </div>
      <div class="login-dorm-error-info-container" *ngIf="dataUpdateFailed">
        <span>Nie udało się zmienić danych</span>
      </div>
      <div class="login-form-submit-button-container">
        <button [disabled]="!termsCheckbox" class="btn btn-primary btn-primary-custom">Zatwierdź</button>
      </div>
    </div>
  </form>
</div>
<waiting-spinner *ngIf="isSpinnerVisible" [text]="'Przetwarzanie...'"></waiting-spinner>