import { Component, OnInit } from '@angular/core';
import { FirebaseInitService } from './services/firebase-init.service';
import { UserDataService } from './services/user-data.service';
import { PromoCampaignService } from './services/promo-campaign.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  public title = 'benefitplatform';

  constructor(
    private firebaseInit: FirebaseInitService,
    private userDataService: UserDataService,
    private promoCampaignService: PromoCampaignService
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.firebaseInit.initialize();
    this.userDataService.initialize();
    this.promoCampaignService.initialize();
  }
}
