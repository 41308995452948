import { Injectable } from '@angular/core';
import { AdministratorManagementService } from '../services/administrator-management.service';
import { ApiService } from '../services/api.service';
import { UserAdditionalDataService } from '../services/user-additional-data.service';
import { UserOrdersService } from '../services/user-orders.service';
import { ExcelReaderService } from './excel-reader.service';
import { UserDataService } from '../services/user-data.service';
import { MongoService } from '../services/mongo.service';
import { ScoringService } from '../services/scoring.service';
import { RankingService } from '../services/ranking.service';

@Injectable({
  providedIn: 'root'
})
export class UsersLoaderService {

  constructor(
    private excelReader: ExcelReaderService,
    private adminManagementService: AdministratorManagementService,
    private userDataService: UserDataService,
    private userAdditionalDataService: UserAdditionalDataService,
    private userOrdersService: UserOrdersService,
    private mongoService: MongoService,
    private apiService: ApiService,
    private scoringService: ScoringService,
    private rankingService: RankingService
  ) {
  }

  public async executeUsersRegisteredProductsListUpdate(excelFile: any) : Promise<boolean> {
    try {
      const newUsersData = await this.getUsersRegisteredProducts(excelFile);
      await this.userDataService.updateUserDataCollection(newUsersData);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  private async getUsersRegisteredProducts(excelFile: any) : Promise<Array<any>> {
    const data = await this.excelReader.parseExcelFile(excelFile);
    const validData = this.collectValidRegisteredProductsData(data);
    return validData;
  }

  private async collectValidRegisteredProductsData(data: any):Promise<Array<any>>{
    const validData: Array<any> = [];
    data.forEach((d: any) => {
      if(this.validateSingleRegisteredProductData(d)){
        validData.push(d);
      }
    })
    return validData;
  }

  private validateSingleRegisteredProductData(data: any) : boolean {
    if (data?.email?.length > 0 &&
      data?.product?.length > 0 && 
      data?.date.length > 0) {
      return true;
    } else {
      console.warn('Invalid registered product data.');
      console.warn(data);
      return false;
    }
  }

  public async executeAddNewUsers(excelFile: any) : Promise<boolean> {

    try {
      const newUsersData = await this.getUsers(excelFile);
      // dodaj nowych niezduplikowanych do firebase jeżeli nie istnieją w firebase
      const firebaseUsersEmails = await this.apiService.getAllExistingUsersEmailAddresses();
      const firebaseNonDuplicatedUsers: Array<any> = [];
      newUsersData.forEach(user => {
        if (!firebaseUsersEmails.some(u => u == user.userEmail.toLowerCase())) {
          firebaseNonDuplicatedUsers.push({userEmail: user.userEmail.toLowerCase(), temporaryPassword: user.temporaryPassword});
        }
      });
      //dodaj do firebase
      console.log("dodaje do firebase ", firebaseNonDuplicatedUsers);
      const firebaseResult:any = await this.apiService.addUsers(firebaseNonDuplicatedUsers);

      //dodaj dla kazdego obiekt userData do kolekcji usersData w mongodb
      if(firebaseResult.status == 200){
        let mongoNonDuplicatedUsers: Array<any> = [];
        for (const user of firebaseNonDuplicatedUsers) {
          let userData = await this.createUserDataObject(user);
          mongoNonDuplicatedUsers.push(userData);
        }
        await this.mongoService.addUsersData(mongoNonDuplicatedUsers);
      }




    ///////////////awaryjnie dodanie tylko do usersData/////////////////////////
    // //pobierz istniejącą kolekcję userData
    //   let _currentMongoUsersDataList = await this.mongoService.getAllUsersData();

    //   for (const data of newUsersData){
    //     let exist = true;
    //     if(_currentMongoUsersDataList && _currentMongoUsersDataList.length > 0){ //sprawdź czy istnieje w aktualnej liscie userData
    //       exist = _currentMongoUsersDataList.find((ud:any) => ud.userEmail.toLowerCase() === data.userEmail.toLowerCase());
    //     } else {
    //       exist = false;
    //     }
    //     if(!exist){    //jeżeli nie istnieje w _currentMongoUsersDataList //utwórz nowy obiekt userData
    //       let userData = await this.createUserDataObject(data);
    //       //zidentyfikuj ile punktów jest przypisane do urządzenia
    //       //dodaj do listy użytkowników
    //       _currentMongoUsersDataList = await this.updateOrAddObject(_currentMongoUsersDataList, userData);
    //     }
    //   }

    //       //wyczyść kolekcję w mongo
    //   await this.mongoService.deleteUsersData();
    //   // await this.mongoService.addUsersData(_currentMongoUsersDataList);

    //   const [tab1, tab2] = await this.splitTable(_currentMongoUsersDataList);

    //   const [tab11, tab12] = await this.splitTable(tab1);
    //   const [tab21, tab22] = await this.splitTable(tab2);

    //   const [tab111, tab112] = await this.splitTable(tab11);
    //   const [tab121, tab122] = await this.splitTable(tab12);
    //   const [tab211, tab212] = await this.splitTable(tab21);
    //   const [tab221, tab222] = await this.splitTable(tab22);
  
    //   await this.mongoService.addUsersData(tab111); 
    //   await this.mongoService.addUsersData(tab112); 
    //   await this.mongoService.addUsersData(tab121); 
    //   await this.mongoService.addUsersData(tab122); 
    //   await this.mongoService.addUsersData(tab211); 
    //   await this.mongoService.addUsersData(tab212); 
    //   await this.mongoService.addUsersData(tab221); 
    //   await this.mongoService.addUsersData(tab222); 

      //////////// koniec awaryjnie ////////////



      return true;
    } catch(error) {
      console.error(error);
      return false;
    }
  }

  async splitTable(tablica:Array<any>) {
    const middleIndex = Math.floor(tablica.length / 2);
    
    const firstHalf = tablica.slice(0, middleIndex);
    const secondHalf = tablica.slice(middleIndex);
  
    return [firstHalf, secondHalf];
  }

  private async createUserDataObject(data:any): Promise<any>{
    return {
      userEmail: data.userEmail.toLowerCase(),
      buildingNumber: "",
      city: "",
      voivodeship: "",
      companyAddress: "",
      companyName: "",
      nipCode: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      points: 0,
      postalCode: "",
      streetName: "",
      // qualified: false,
      prizeAmount: 0,
      registered: false,
      registeredProducts :[]
    };
  }

  private async updateOrAddObject(list:any, objectToUpdate:any): Promise<Array<any>> {
    let found = false;
    const updatedList = list.map((item:any) => {
        if (item.userEmail === objectToUpdate.userEmail) {
            found = true;
            return objectToUpdate;
        }
        return item;
    });

    if (!found) {
        updatedList.push(objectToUpdate);
    }
    return updatedList;
  }

  public async executeNonAdminUsersReplacement(excelFile: any) : Promise<boolean> {
    try {
      const adminEmailAddresses = await this.adminManagementService.getAllAdminsEmailAddresses();
      // const newUsersData = await this.getUsers(excelFile);
      // await this.userOrdersService.clearOrdersData();
      // await this.userAdditionalDataService.removeUsersAdditionalData(adminEmailAddresses);
      console.log(adminEmailAddresses);
      const usersRemovalResult = await this.apiService.removeNonAdminUsers(adminEmailAddresses);
      // const usersAddErrors = await this.apiService.addUsers(newUsersData);
      // const additionalDataAddResult = await this.addAdditionalUsersData(newUsersData, usersAddErrors);
      return true; //this.summarizeProcessResult(usersRemovalResult, usersAddErrors, additionalDataAddResult);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  private summarizeProcessResult(usersRemovalResult: any,
      userAddEmailAddressesErrors: Array<string>,
      usersAdditionalDataAddResult: boolean) : boolean {
    return usersRemovalResult.total === usersRemovalResult.successes
        && usersRemovalResult.failures === 0
        && userAddEmailAddressesErrors.length === 0
        && usersAdditionalDataAddResult;
  }

  private async getUsers(excelFile: any) : Promise<Array<any>> {
    const newUsersData = await this.excelReader.parseExcelFile(excelFile);
    console.log("newUsersData ", newUsersData);
    const validUsersData = this.collectValidUsersData(newUsersData);
    console.log("validUsersData ", validUsersData);
    return this.getNonDuplicatedUsers(validUsersData);
  }

  private collectValidUsersData(excelUsersData: Array<any>) : Array<any> {
    const validUsersData: Array<any> = [];
    excelUsersData.forEach(u => {
      if (u?.userEmail?.length > 0 && u?.temporaryPassword?.length >= 8) {
        validUsersData.push(u);
      }
    });
    return validUsersData;
  }

  private getNonDuplicatedUsers(usersCollection: Array<any>) : Array<any> {
    const nonDuplicatedUsers: Array<any> = [];
    usersCollection.forEach(user => {
      if (!nonDuplicatedUsers.some(u => u.userEmail == user.userEmail)) {
        nonDuplicatedUsers.push(user);
      }
    });
    return nonDuplicatedUsers;
  }

  // private async addAdditionalUsersData(validUsersData: Array<any>, errorEmailAddresses: Array<string>) : Promise<boolean> {
  //   let anyError = false;
  //   for (let i = 0; i < validUsersData.length; i++) {
  //     if (!errorEmailAddresses.includes(validUsersData[i].userEmail)) {
  //       const result = await this.userAdditionalDataService
  //         .addUserAdditionalData(validUsersData[i])
  //       if (!result) {
  //         anyError = true;
  //       }
  //     }
  //   }
  //   return !anyError;
  // }
}
