import { Injectable } from '@angular/core';
import { Firestore, getFirestore } from '@firebase/firestore';
import axios from 'axios';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { ApiService } from './api.service';

const headers = {
  'Content-Type': 'application/json'
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseInitService {

  private _database: Firestore | null = null;

  public get database() {
    return this._database as any;
  }

  constructor(private aspiService: ApiService) {}

  public async initialize() { 
    const fireconfig = await this.aspiService.getFirestoreConfig();
    const firebaseApp = initializeApp(fireconfig);
    this._database = getFirestore(firebaseApp);
  }
}
