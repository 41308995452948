<div class="backdrop"></div>
<div class="spinner-container">
  <div class="title-header">
    <span>{{ text }}</span>
  </div>
  <div class="spinner-only-container">
    <div class="rotation-container">
      <div class="outer-spinner"></div>
      <div class="inner-spinner"></div>
    </div>
  </div>
</div>