import { Injectable } from '@angular/core';
import { MongoService } from './mongo.service';

@Injectable({
  providedIn: 'root'
})
export class ScoringService {

  private scoresCache: Array<any> = [];

  constructor(
    private mongoService: MongoService
  ) { }

  public async getAllExistingScores(ommitCache: boolean = false) : Promise<Array<any>> {
    if (ommitCache === false && this.scoresCache?.length > 0) {
      return this.scoresCache;
    } else {
      this.scoresCache = await this.fetchScores();
      return this.scoresCache;
    }
  }

  public async clearScoringData() : Promise<void> {
    const scoringData = await this.getAllExistingScores(true);
    if (scoringData?.length > 0) {
      await this.mongoService.deleteScoringData();
    }
  }

  public async addScoringToStorage(scoringData: Array<any>) : Promise<number> {
    let result: number = 0;
    for (let i = 0; i < scoringData.length; i++) {
      if (await this.addSingleScoreToStorage(scoringData[i])) {
        result++;
      }
    }
    return result;
  }

  private async fetchScores() : Promise<Array<any>> {
    try {
      let data = await this.mongoService.getAllScoresData();
      const scores: Array<any> = [];
      data.forEach((d:any) => {
        const data = d;
        scores.push(data);
      });
      return scores;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  private async addSingleScoreToStorage(score: any): Promise<boolean> {
    try {
      await this.mongoService.addScore(score);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}
