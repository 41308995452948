import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.css']
})
export class InfoDialogComponent {

  public title: string = '';
  public message: string = '';
  public isErrorInfo: boolean = false;
  public okFunc: (() => void) | null = null;

  constructor(private dialogReference: MatDialogRef<InfoDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public config: any) {
    this.title = config?.title ?? '';
    this.message = config?.message ?? '';
    this.isErrorInfo = config?.isErrorInfo ?? false;
    this.okFunc = config?.okFunc ?? null;
  }

  public confirmMessage() : void {
    if (this.okFunc) {
      this.okFunc();
    }
    this.dialogReference.close();
  }
}
