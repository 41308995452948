import { Component, Input } from '@angular/core';
import { NavigatorService } from 'src/app/services/navigator.service';

@Component({
  selector: 'benefit-presenter',
  templateUrl: './benefit-presenter.component.html',
  styleUrls: ['./benefit-presenter.component.css']
})
export class BenefitPresenterComponent {

  @Input() benefitData: any;

  constructor(private navigator: NavigatorService) {

  }

  public redirestToBenefitDetailsScreen() : void {
    this.navigator.redirectToBenefitDetailsScreen(this.benefitData._id);
  }
}
