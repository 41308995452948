import { Component, Input } from '@angular/core';

@Component({
  selector: 'waiting-spinner',
  templateUrl: './waiting-spinner.component.html',
  styleUrls: ['./waiting-spinner.component.css'],
})
export class WaitingSpinnerComponent {
  @Input() text: string = 'Oczekiwanie';

  constructor() {}
}
