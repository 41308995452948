<div class="application-view-main-container">
    <div class="application-view-title">Zarządzanie użytkownikami</div>
    <div class="table-container">
        <div class="items-filter">
            <div class="filter-input-container">
                <span  style="font-weight: 500;">Filtr email:</span>
                <input class="form-control" type="text" placeholder="adres email" value=""
                    (input)="filterValueChanged($event)" />
            </div>
        </div>
        <items-list 
            [data]="displayedItems" 
            (itemClickedEvent)="openItem($event)">
        </items-list>
        <div class="paginator-element">
            <paginator 
                [itemsSource]="data" 
                [filterValue]="currentFilterValue" 
                [filterPropertyName]="'userEmail'"
                (displayItemsEvent)="updateDisplayedList($event)"></paginator>
        </div>
    </div>
    <div class="return-button-container">
        <button type="button" class="btn btn-sm btn-secondary btn-secondary-custom"
          (click)="redirectToAdministrationScreen()">Wróć</button>
      </div>
</div>
<waiting-spinner *ngIf="isSpinnerVisible" [text]="'Przetwarzanie...'"></waiting-spinner>