import { Component } from '@angular/core';
import { NavigatorService } from 'src/app/services/navigator.service';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-view-landing-page',
  templateUrl: './view-landing-page.component.html',
  styleUrls: ['./view-landing-page.component.css']
})
export class ViewLandingPageComponent {

  public get treatUserAsLogger() {
    return this.userDataService.isUserLoggedIn &&
      this.userDataService.isUserPasswordChanged;
  }

  constructor(
    private userDataService: UserDataService,
    private navigator: NavigatorService
  ) { 
  }

  public redirectToLogin() : void {
    this.navigator.redirectToLoginScreen();
  }

  public redirectToBenefits() : void {
    this.navigator.redirectToBenefitsScreen();
  }
}
