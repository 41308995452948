import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Alert, AlertType } from './alert.model';

@Injectable({ providedIn: 'root' })

export class AlertService {
    private newAlertSource = new Subject<Alert>();
    newAlert$ = this.newAlertSource.asObservable();

    alert(alert: Alert){
      this.newAlertSource.next(alert);
    }

    private deleteAlertSource = new Subject<string>();
    deleteAlert$ = this.deleteAlertSource.asObservable();
    deleteAlert(alertAlias: string){
      this.deleteAlertSource.next(alertAlias);
    }

    success(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Success, message }));
    }

    error(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Error, message }));
    }

    info(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Info, message }));
    }

    warn(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Warning, message }));
    }
}
