import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MongoService } from 'src/app/services/mongo.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { YesNoDialogComponent } from '../../common/yes-no-dialog/yes-no-dialog.component';
import { InfoDialogComponent } from '../../common/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PromoCampaignService } from 'src/app/services/promo-campaign.service';
import { AlertService } from '../../common/alert/alert.service';

@Component({
  selector: 'user-details-view',
  templateUrl: './user-details-view.component.html',
  styleUrl: './user-details-view.component.css'
})
export class UserDetailsViewComponent {
  userData: any;
  rankingData: any;
  pointsTotal: number = 0;
  isSpinnerVisible:boolean = false;

  constructor(
    private route: ActivatedRoute,
    private navigator: NavigatorService,
    private mongoService: MongoService,
    private userDataService: UserDataService,
    private dialog: MatDialog,
    private promoCampaignService: PromoCampaignService,
    private alertService: AlertService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isSpinnerVisible = true;
    this.userData = await this.mongoService.getUserDataByEmail(this.userDataService?.currentUser?.email);
    this.rankingData = await this.userDataService.getUserRankingAndCount(this.userData);
    if(this.promoCampaignService.usersMessageActive){
      this.alertService.error(this.promoCampaignService.userMessage, {alias: "test", autoClose: true, keepAfterRouteChange: true, priority: 1});
    }
    this.countPointsTotal();
    this.isSpinnerVisible = false;
  }

  async executeSaveChanges() : Promise<void> {
    this.isSpinnerVisible = true;
    let result = await this.mongoService.updateUserData(this.userData);
    this.isSpinnerVisible = false;
    if(result.status == 200){
      this.showSuccessDialog();
    } else {
      this.showErrorDialog();
    }
  }

  private showSuccessDialog() : void {
    this.dialog.open(InfoDialogComponent, { disableClose: true, data: {
      title: 'Sukces',
      message: 'Zamiany zostaly zapisane.',
      isErrorInfo: false,
      okFunc: null
    } });
  }

  private showErrorDialog() : void {
    this.dialog.open(InfoDialogComponent, { disableClose: true, data: {
      title: 'Błąd',
      message: "Wystąpił błąd podczas zapisu. Jeżeli problem się powtarza skontaktuj się z administratorem",
      isErrorInfo: true,
      okFunc: null
    } });
  }

  public redirectToAdministrationScreen() : void {
    this.navigator.redirectToUserScreen();
  }

  countPointsTotal(){
    this.userData?.registeredProducts?.forEach((product: any) => {
      this.pointsTotal += product.score;
    });
  }
}
