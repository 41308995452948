import { Injectable } from '@angular/core';
import { PrizesService } from './prizes.service';
import { MongoService } from './mongo.service';

@Injectable({
  providedIn: 'root'
})
export class RankingService {

  constructor(
    private prizesService: PrizesService,
    private mongoService: MongoService,
  ) { }

  public async prepareRankng(){
    let usersData = await this.mongoService.getAllUsersData();
    usersData = await this.countAllUsersDataPoints(usersData);
    usersData = await this.prepareRankingArray(usersData);
    usersData = await this.assignRewards(usersData);
    await this.mongoService.deleteUsersData();
    // await this.mongoService.addUsersData(usersData);    

    // const [tab1, tab2] = await this.splitTable(usersData);
    // const [tab11, tab12] = await this.splitTable(tab1);
    // const [tab21, tab22] = await this.splitTable(tab2);

    // await this.mongoService.addUsersData(tab11); 
    // await this.mongoService.addUsersData(tab12); 
    // await this.mongoService.addUsersData(tab21); 
    // await this.mongoService.addUsersData(tab22); 

    const [tab1, tab2] = await this.splitTable(usersData);

    const [tab11, tab12] = await this.splitTable(tab1);
    const [tab21, tab22] = await this.splitTable(tab2);

    const [tab111, tab112] = await this.splitTable(tab11);
    const [tab121, tab122] = await this.splitTable(tab12);
    const [tab211, tab212] = await this.splitTable(tab21);
    const [tab221, tab222] = await this.splitTable(tab22);

    await this.mongoService.addUsersData(tab111); 
    await this.mongoService.addUsersData(tab112); 
    await this.mongoService.addUsersData(tab121); 
    await this.mongoService.addUsersData(tab122); 
    await this.mongoService.addUsersData(tab211); 
    await this.mongoService.addUsersData(tab212); 
    await this.mongoService.addUsersData(tab221); 
    await this.mongoService.addUsersData(tab222); 
  }

  async splitTable(tablica:Array<any>) {
    const srodkowyIndeks = Math.floor(tablica.length / 2);
    
    const pierwszaPolowa = tablica.slice(0, srodkowyIndeks);
    const drugaPolowa = tablica.slice(srodkowyIndeks);
  
    return [pierwszaPolowa, drugaPolowa];
  }


  public async countAllUsersDataPoints(usersData: Array<any>) {
    try {
      for (const userData of usersData) {
        if (userData.registered) {
          userData.points = await this.countRegisteredProductsScore(userData);
        } else {
          userData.points = 0;
        }
      }
      return usersData;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  private async countRegisteredProductsScore(userData: any) {
    try {
      return userData.registeredProducts.reduce((sum: any, product: any) => sum + product.score, 0);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  //funkcja tworzy ranking
  //sortuje po liczbie zgromadzonych przez użtkowników punktów
  //jeżeli liczba punktów jest taka sama dla więcej niż jednego użtykownika bierze pod uwagę najwczesniejszą datę rejestracji któregokolwiek z urządzeń
  public async prepareRankingArray(allUsersData: any): Promise<Array<any>> {
    try {
      // return allUsersData.sort((a:any, b:any) => b.points - a.points);
      return allUsersData.sort((a: any, b: any) => {
        if (a.points !== b.points) {
          return b.points - a.points; // Sortuj według points malejąco
        }
        const earliestDateA = getEarliestDate(a.registeredProducts);
        const earliestDateB = getEarliestDate(b.registeredProducts);
        return earliestDateA - earliestDateB;

        function getEarliestDate(products: any) {
          return products.reduce((earliest: any, product: any) => {
            const date = parseDate(product.date);
            return date < earliest ? date : earliest;
          }, new Date(9999, 11, 31)); // Data bardzo daleko w przyszłości
        }
        function parseDate(dateStr: any) {
          // Format daty: "24-02-28 11:11"
          const [datePart, timePart] = dateStr.split(' ');
          const [year, month, day] = datePart.split('-').map(Number);
          const [hour, minute] = timePart.split(':').map(Number);

          // Tworzenie daty
          return new Date(year + 2000, month - 1, day, hour, minute);
        }
      });
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  //iteruje po liście posortowanych wg liczby punktów użytkowników, przypisuje wartość pieniężną nagrody do użytkownika
  public async assignRewards(rankingSortedUsersData: any): Promise<Array<any>> {
    try {
      //pobierz tabelę nagrod prizes
      let rewardsList = await this.prizesService.getAllPrizes();
      let iterator = 0;
      //wyzeruj wszystkim prizeAmount
      rankingSortedUsersData = rankingSortedUsersData.map((elem:any) => {
        return { ...elem, prizeAmount: 0 };
      });
      //przypisz prizeAmount
      rewardsList.forEach((reward: any) => {
        if (rankingSortedUsersData[iterator] && (rankingSortedUsersData[iterator].points > 0) && rankingSortedUsersData[iterator].registered) {
          rankingSortedUsersData[iterator].prizeAmount = reward.prizeAmount;
        }
        iterator++
      });
      iterator = 0;
      return rankingSortedUsersData;
    } catch (e) {
      console.error(e);
      return [];
    }
  }
}
