import { Component } from '@angular/core';
import { NavigatorService } from 'src/app/services/navigator.service';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'user-view',
  templateUrl: './user-view.component.html',
  styleUrl: './user-view.component.css'
})
export class UserViewComponent {

  constructor(
    private navigatorService: NavigatorService,
    public userDataService: UserDataService
  ){ }

  redirecTo(moduleName: string){
    switch (moduleName) {
      case 'userDetails':
        this.navigatorService.redirectToUserDetailsScreen();
        break;
      case 'passwordChange':
        this.navigatorService.redirectToPasswordChangeScreen();
        break;   
      default:
        break;
    }
  }
}
