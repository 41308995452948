<div [ngClass]="{ 'header-container-enlargable' : enlargeHeader, 'header-container-expanded': isHamburgerMenuExpanded }"
    class="header-container">
  <div class="header-bar">
    <div class="logo-nav-container">
      <img class="menu-logo" src="assets/logo.png" (click)="redirectToLandingPage($event)"/>
      <div class="menu-items-container" [ngClass]="{ 'menu-items-container-expanded' : isHamburgerMenuExpanded }">
        <button type="button" class="menu-button" *ngIf="!isInLandingScreen"
          (click)="redirectToLandingPage($event)">STRONA GŁÓWNA</button>
        <button type="button" class="menu-button" *ngIf="shouldOrdinaryUserMenuBeDisplayed"
          (click)="redirectToBenefitsScreen($event)">NAGRODY</button>
        <button type="button" class="menu-button" *ngIf="shouldOrdinaryUserMenuBeDisplayed"
            (click)="redirectToCartScreen($event)">
          <span>KOSZYK</span>
          <span class="cart-items-amount-presenter" *ngIf="cartItemsAmount > 0">{{ cartItemsAmount }}</span>
        </button>
        <button type="button" class="menu-button" *ngIf="shouldAdminMenuBeDisplayed"
          (click)="redirectToAdministrationScreen($event)">ADMINISTRACJA</button>
        <button type="button" class="menu-button" *ngIf="shouldOrdinaryUserMenuBeDisplayed"
          (click)="redirectToUserScreen($event)">UŻYTKOWNIK</button>          
        <button type="button" class="menu-button" (click)="redirectToContactScreen()"
          *ngIf="!isInContsctScreen">KONTAKT</button>
        <button type="button" class="menu-button" *ngIf="shouldOrdinaryUserMenuBeDisplayed"
          (click)="logout($event)">WYLOGUJ</button>
        <button type="button" class="menu-button" *ngIf="!isUserLoggedIn && !isInLoggingScreen"
          (click)="redirectToLoginScreen($event)">ZALOGUJ</button>
      </div>
    </div>
  </div>
  <span class="hamburger-menu" (click)="toggleHamburgerMenu()">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
    </svg>
  </span>
</div>