<div class="application-view-main-container">
  <div class="application-view-title">Raporty</div>
  <div class="available-reports-list">
    <report-presenter [reportTitle]="rankingReportTitle"
      [reportDescription]="rankingReportDescription"
      (generateReport)="generateRanking()"></report-presenter>
    <report-presenter *ngIf="this.userDataService.isUserAdmin" [reportTitle]="ordersReportTitle"
      [reportDescription]="ordersReportDescription"
      (generateReport)="generateOrdersReport()"></report-presenter>
    <report-presenter *ngIf="this.userDataService.isUserAdmin" [reportTitle]="usersWithoutOrderReportTitle"
      [reportDescription]="usersWithoutOrderReportDescription"
      (generateReport)="generateUsersWithoutOrderReport()"></report-presenter>
    <report-presenter *ngIf="this.userDataService.isUserAdmin" [reportTitle]="orderedBenefitsReportTitle"
      [reportDescription]="orderedBenefitsReportDescription"
      (generateReport)="generateOrderedBenefitsReport()"></report-presenter>
    <report-presenter *ngIf="this.userDataService.isUserAdmin" [reportTitle]="unregisteredUsersReportTitle"
      [reportDescription]="unregisteredUsersReportDescription"
      (generateReport)="generateUnregisteredUsersReport()"></report-presenter>
  </div>
  <div class="return-button-container">
    <button type="button" class="btn btn-sm btn-secondary btn-secondary-custom"
      (click)="redirectToAdministrationScreen()">Wróć</button>
  </div>
</div>
<waiting-spinner *ngIf="isSpinnerVisible" [text]="'Trwa generowanie...'"></waiting-spinner>