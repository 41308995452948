import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigatorService {
  private static _landingPagePath: string = '';
  private static _loginScreenPath: string = 'login';
  private static _benefitsScreenPath: string = 'benefits';
  private static _benefitDetailsScreenPath: string = 'benefit';
  private static _initialDataScreenPath: string = 'initialData';
  private static _passwordChangeScreenPath: string = 'passwordChange';
  private static _passwordResetScreenPath: string = 'passwordReset';
  private static _adminDataScreenPath: string = 'adminDataManagement';
  private static _cartScreenPath: string = 'cart';
  private static _contactsScreenPath: string = 'contacts';
  private static _administrationScreenPath: string = 'administration';
  private static _usersManagementScreenPath: string = 'usersManagement';
  private static _scoringScreenPath: string = 'scoring';
  private static _prizesScreenPath: string = 'prizes';
  private static _databaseManagementScreenPath: string = 'databaseManagement';
  private static _emailMessagesScreenPath: string = 'emailMessages';
  private static _adminReportsScreenPath: string = 'adminReports';
  private static _promoCampaignManagementPath: string = 'promoCampaignManagement';
  private static _userEditScreenPath: string = 'userEdit';
  private static _userPath: string = 'user';
  private static _userDetailsPath: string = 'userDetails';

  public static get landingPagePath() {
    return this._landingPagePath;
  }

  public static get loginScreenPath() {
    return NavigatorService._loginScreenPath;
  }

  public static get benefitsScreenPath() {
    return NavigatorService._benefitsScreenPath;
  }

  public static get benefitDetailsScreenPath() {
    return this._benefitDetailsScreenPath;
  }

  public static get initialDataScreenPath() {
    return NavigatorService._initialDataScreenPath;
  }

  public static get passwordResetScreenPath() {
    return NavigatorService._passwordResetScreenPath;
  }

  public static get adminDataScreenPath() {
    return NavigatorService._adminDataScreenPath;
  }

  public static get cartScreenPath() {
    return NavigatorService._cartScreenPath;
  }

  public static get contactsScreenPath() {
    return NavigatorService._contactsScreenPath;
  }

  //ADMINISTRACJA

  public static get administrationScreenPath() {
    return NavigatorService._administrationScreenPath;
  }

  public static get usersManagementcreenPath() {
    return NavigatorService._usersManagementScreenPath;
  }

  public static get scoringScreenPath() {
    return NavigatorService._scoringScreenPath;
  }

  public static get prizesScreenPath() {
    return NavigatorService._prizesScreenPath;
  }

  public static get databaseManagementScreenPath() {
    return NavigatorService._databaseManagementScreenPath;
  }
  
  public static get emailMessagesScreenPath() {
    return NavigatorService._emailMessagesScreenPath;
  }

  public static get adminReportsScreenPath() {
    return NavigatorService._adminReportsScreenPath;
  }

  public static get promoCampaignManagementPath() {
    return NavigatorService._promoCampaignManagementPath;
  }

  public static get userEditScreenPath() {
    return NavigatorService._userEditScreenPath;
  }

  //UŻYTKOWNIK
  public static get userPath() {
    return NavigatorService._userPath;
  }

  public static get userDetailsPath() {
    return NavigatorService._userDetailsPath;
  }

  public static get passwordChangeScreenPath() {
    return NavigatorService._passwordChangeScreenPath;
  }

  constructor(private router: Router) { }

  public redirectToLandingPage() : void {
    this.router.navigate([NavigatorService._landingPagePath]);
  }

  public redirectToLoginScreen() : void {
    this.router.navigate([NavigatorService._loginScreenPath]);
  }

  public redirectToBenefitsScreen() : void {
    this.router.navigate([NavigatorService._benefitsScreenPath]);
  }

  public redirectToBenefitDetailsScreen(benefitIdentity: string) : void {
    if (benefitIdentity) {
      this.router.navigate([NavigatorService._benefitDetailsScreenPath],
        { queryParams : { id: benefitIdentity }});
    }
  }

  public redirectToInitialDataScreen() : void {
    this.router.navigate([NavigatorService._initialDataScreenPath]);
  }

  public redirectToPasswordChangeScreen() : void {
    this.router.navigate([NavigatorService._passwordChangeScreenPath]);
  }

  public redirectToPasswordResetScreen() : void {
    this.router.navigate([NavigatorService._passwordResetScreenPath]);
  }

  public redirectToAdminDataSsreen() : void {
    this.router.navigate([NavigatorService._adminDataScreenPath]);
  }

  public redirectToCartScreen() : void {
    this.router.navigate([NavigatorService._cartScreenPath]);
  }

  public redirectToContactsScreen() : void {
    this. router.navigate([NavigatorService._contactsScreenPath]);
  }

  public redirectToAdministrationScreen() : void {
    this. router.navigate([NavigatorService._administrationScreenPath]);
  }

  public redirectToEmailMessagesScreen() : void {
    this. router.navigate([NavigatorService._emailMessagesScreenPath]);
  }

  public redirectToScoringScreen() : void {
    this. router.navigate([NavigatorService._scoringScreenPath]);
  }

  public redirectToPrizesScreen() : void {
    this. router.navigate([NavigatorService._prizesScreenPath]);
  }

  public redirectToDatabaseManagementScreen() : void {
    this. router.navigate([NavigatorService._databaseManagementScreenPath]);
  }

  public redirectToUsersManagementScreen() : void {
    this. router.navigate([NavigatorService._usersManagementScreenPath]);
  }

  public redirectToAdminReportsScreen() : void {
    this.router.navigate([NavigatorService._adminReportsScreenPath]);
  }

  public redirectToPromoCampaignManagementScreen() : void {
    this. router.navigate([NavigatorService._promoCampaignManagementPath]);
  }
  
  public redirectToUserEditScreen(email: string) : void {
    if(email){
      this. router.navigate([NavigatorService._userEditScreenPath],
        { queryParams : { userEmail: email }});
    }
  }

  public redirectToUserScreen() : void {
      this. router.navigate([NavigatorService._userPath]);
  }

  public redirectToUserDetailsScreen() : void {
    this. router.navigate([NavigatorService._userDetailsPath]);
}
}
