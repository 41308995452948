import { Input, Output, EventEmitter, Component, OnInit } from '@angular/core';

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {

  private _itemsSource: Array<any> = [];
  private _filterValue: string = "";

  @Input()
  get itemsSource(): Array<any> { return this._itemsSource };
  set itemsSource(itemsSource: Array<any>) {
    this._itemsSource = itemsSource ?? [];
    this.executeLogic();
  }

  @Input()
  get filterValue(): string { return this._filterValue };
  set filterValue(filterValue: string) {
    this._filterValue = filterValue ?? '';
    this.executeLogic();
  }

  @Input() startPageNumber: number = 10;
  @Input() filterPropertyName: string = "";
  @Input() second_filterPropertyName: string = "";
  //filtruj po nazwie właściwości obiektu, lista obiektów - obiekty z jedną właściwością
  @Input() filterByListObjectPropertyName_keyValue: boolean = false;
  @Output() displayItemsEvent = new EventEmitter<any>();

  private _pageSize: number = 5;
  pageNumber: number = 1;
  pagesAmount: number = 1;

  get pageSize(): number { return this._pageSize }
  set pageSize(pageSize: number) {
    this._pageSize = +pageSize ?? 10;
    this.executeLogic();
  }

  get isLowerBageButtonEnabled() {
    return this.pageNumber > 1;
  }

  get isHigherPageButtonEnabled() {
    return this.pageNumber < this.pagesAmount;
  }

  constructor() {
  }

  ngOnInit(): void {
    this.pageSize = this.startPageNumber;
    this.executeLogic();
  }

  goToFirstPage() {
    this.pageNumber = 1;
    this.executeLogic();;
  }

  goToPreviousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber--;
      this.executeLogic();
    }
  }

  goToNextPage() {
    if (this.pageNumber < this.pagesAmount) {
      this.pageNumber++;
      this.executeLogic();
    }
  }

  goToLastPage() {
    this.pageNumber = this.pagesAmount;
    this.executeLogic();
  }

  private executeLogic() {
    let filteredItems;
    if (this.second_filterPropertyName) {
      filteredItems = this.itemsSource?.filter(i =>
        i[this.filterPropertyName][this.second_filterPropertyName]?.toLowerCase()?.includes(this.filterValue?.toLowerCase())) ?? [];
    } else if (this.filterByListObjectPropertyName_keyValue) {
      let testfilteredItems: any = [];
      this.itemsSource.forEach(x => {
        if (Object.keys(x)[0].toLowerCase().includes(this.filterValue?.toLowerCase())) {
          testfilteredItems.push(x);
        }
      });
      filteredItems = testfilteredItems;

    } else {
      filteredItems = this.itemsSource?.filter(i => i[this.filterPropertyName]?.toString()?.toLowerCase()?.includes(this.filterValue?.toLowerCase())) ?? [];
    }
    this.pagesAmount = this.countPagesNumber(filteredItems);
    this.pageNumber = this.getCurrentPageNumber(filteredItems);
    const itemsToDisplay = this.getItemsToDisplay(filteredItems);
    this.displayItemsEvent.emit(itemsToDisplay);
  }

  private countPagesNumber(items: Array<any>): number {
    const pagesAmount = Math.ceil(items?.length / this.pageSize) ?? 1;
    if (pagesAmount > 0) {
      return pagesAmount;
    }
    return 1;
  }

  private getCurrentPageNumber(items: Array<any>): number {
    if (this.pageNumber < 1) {
      return 1;
    }
    if (this.pageNumber > this.pagesAmount) {
      return this.pagesAmount;
    }
    return this.pageNumber;
  }

  private getItemsToDisplay(items: Array<any>): Array<any> {
    const startIndex = (this.pageNumber - 1) * this.pageSize;
    return items.slice(startIndex, startIndex + this.pageSize);
  }
}
