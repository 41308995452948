import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'the-header',
  templateUrl: './the-header.component.html',
  styleUrls: ['./the-header.component.css']
})
export class TheHeaderComponent implements OnInit, OnDestroy {
  private _shouldEnlargeHeader: boolean = true;
  private _isHamburgerMenuExpanded: boolean = false;
  private _currentRoute: string = '';

  public get shouldOrdinaryUserMenuBeDisplayed() {
    return this.userDataService.isUserLoggedIn &&
      this.userDataService.isUserPasswordChanged;
  }

  public get shouldAdminMenuBeDisplayed() {
    return this.shouldOrdinaryUserMenuBeDisplayed &&
      (this.userDataService.isUserAdmin || this.userDataService.isUserPanasonicAdmin);
  }

  public get cartItemsAmount() {
    return this.cartService.totalItemsQuantity;
  }

  public get enlargeHeader() {
    return this._shouldEnlargeHeader;
  }

  public get isHamburgerMenuExpanded() {
    return this._isHamburgerMenuExpanded;
  }

  public get isUserLoggedIn() {
    return this.userDataService.isUserLoggedIn;
  }

  public get isInLoggingScreen() {
    return this._currentRoute.includes(NavigatorService.loginScreenPath);
  }

  public get isInLandingScreen() {
    return this._currentRoute === '/';
  }

  public get isInContsctScreen() {
    return this._currentRoute.includes(NavigatorService.contactsScreenPath);
  }

  public get currentRoute() {
    return this._currentRoute;
  }

  public get navigatorCartPath() {
    return NavigatorService.cartScreenPath;
  }

  public get navigatorBenefitsPath() {
    return NavigatorService.benefitsScreenPath;
  }

  public get navigatorAdministrationPath() {
    return NavigatorService.administrationScreenPath;
  }

  public get navigatorcontactPath() {
    return NavigatorService.contactsScreenPath;
  }

  public get navigatorUserPath() {
    return NavigatorService.userPath;
  }

  constructor(
      public userDataService: UserDataService,
      private navigator: NavigatorService,
      private cartService: CartService,
      private router: Router) {
    this.determineHeaderEnlargement();
  }

  ngOnInit(): void {
    window.addEventListener("scroll", this.determineHeaderEnlargement);
    this.router.events.subscribe(val => {
      const route = (val as any)?.url ?? null;
      if (route?.length > 0) {
        this._currentRoute = route;
      }
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener("scroll", this.determineHeaderEnlargement);
  }

  public toggleHamburgerMenu() : void {
    this._isHamburgerMenuExpanded = !this._isHamburgerMenuExpanded;
  }

  public redirectToLandingPage(event: any) : void {
    this.blurMenuFocus(event);
    this.closeHamburger();
    this.navigator.redirectToLandingPage();
  }

  public redirectToLoginScreen(event: any) : void {
    this.blurMenuFocus(event);
    this.closeHamburger();
    this.navigator.redirectToLoginScreen();
  }

  public async logout(event: any) : Promise<void> {
    this.blurMenuFocus(event);
    this.closeHamburger();
    await this.userDataService.signOut(true);
  }

  public redirectToPasswordChangeScreen(event: any) : void {
    this.blurMenuFocus(event);
    this.closeHamburger();
    this.navigator.redirectToPasswordChangeScreen();
  }

  public redirectToBenefitsScreen(event: any) : void {
    this.blurMenuFocus(event);
    this.closeHamburger();
    this.navigator.redirectToBenefitsScreen();
  }

  public redirectToCartScreen(event: any) : void {
    this.blurMenuFocus(event);
    this.closeHamburger();
    this.navigator.redirectToCartScreen();
  }
  
  public redirectToContactScreen() : void {
    this.closeHamburger();
    this.navigator.redirectToContactsScreen();
  }

  // public redirectToAdminReportsView(event: any) : void {
  //   this.blurMenuFocus(event);
  //   this.closeHamburger();
  //   this.navigator.redirectToAdminReportsScreen();
  // }

  // public redirectToAdminDataScreen(event: any) : void {
  //   this.blurMenuFocus(event);
  //   this.closeHamburger();
  //   this.navigator.redirectToAdminDataSsreen();
  // }

  public redirectToAdministrationScreen(event: any) : void {
    this.blurMenuFocus(event);
    this.closeHamburger();
    this.navigator.redirectToAdministrationScreen();
  }

  public redirectToUserScreen(event: any) : void {
    this.blurMenuFocus(event);
    this.closeHamburger();
    this.navigator.redirectToUserScreen();
  }

  private blurMenuFocus(event: any) : void {
    event.target.parentElement.parentElement.blur();
  }

  private determineHeaderEnlargement = () : void => {
    this._shouldEnlargeHeader = window.scrollY <= 100;
  }

  private closeHamburger() : void {
    this._isHamburgerMenuExpanded = false;
  }
}
