import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../../common/info-dialog/info-dialog.component';
import { MongoService } from 'src/app/services/mongo.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { PromoCampaignService } from 'src/app/services/promo-campaign.service';
import { RankingService } from 'src/app/services/ranking.service';

@Component({
  selector: 'promo-campaign-management',
  templateUrl: './promo-campaign-management-view.component.html',
  styleUrl: './promo-campaign-management-view.component.css'
})
export class PromoCampaignManagementComponent {
  public isSpinnerVisible: boolean = false;
  public startCampaignSwitch: boolean = false;
  public usersMessageSwitch: boolean = false;
  public campaignForm!: FormGroup;

  // public conversionFactorEdit: boolean = false;

  public startCampaignTitle: string = "Uruchomiono kampanię promocyjną.";
  public startCampaignMessage: string = 'Od tego momentu, po zapisaniu zmian użytkownicy bedą mogli zamawiać benefity.';
  public stopCampaignTitle: string = "Zatrzymano kampanię promocyjną.";
  public stopCampaignMessage: string = 'Od tego momentu, po zapisaniu zmian użytkownicy nie bedą mogli zamawiać benefitów.';

  public messageForUsersTitle: string = "Wiadomość dla użytkowników.";
  public startMessageForUsersMessage: string = 'Po zapisaniu zmian wiadomość dla użytkowników będzie widoczna.';
  public stopMessageForUsersMessage: string = 'Po zapisaniu zmian wiadomość dla użytkowników będzie niewidoczna.';

  public saveDataTitle: string = "Zapis ustawień";
  public saveDataOkMessage: string = 'Pomyślnie zapisano ustawienia.';
  public saveDataErrorMessage: string = 'Wystąpił błąd podczas zapisu ustawień.';

  constructor(
    private navigator: NavigatorService,
    private mongoService: MongoService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private promoCampaignService: PromoCampaignService,
    private rankingService: RankingService
  ) { }

  async ngOnInit(): Promise<void> {
    this.isSpinnerVisible = true;
    this.campaignForm = this.fb.group({
      campaignActive: false,
      usersMessageActive: false,
      userMessage: '',
      // conversionFactorEdit: false,
      // conversionFactor: [{ value: 0, disabled: true}]
    });
    await this.loadCampaignSettings();
    this.campaignForm.get('conversionFactor')?.disable();
    this.isSpinnerVisible = false;
  }
  
  async loadCampaignSettings() {
    // let result:any = await this.mongoService.getPromoCampaignSettingsData();
    // if (result[0]){
      this.campaignForm.patchValue({
        campaignActive: this.promoCampaignService.campaignActive,
        usersMessageActive: this.promoCampaignService.usersMessageActive,
        userMessage: this.promoCampaignService.userMessage,
            // campaignActive: result[0].campaignActive,
            // usersMessageActive: result[0].usersMessageActive,
            // userMessage: result[0].userMessage,
            // conversionFactor: result[0].conversionFactor
          });
      this.startCampaignSwitch = this.promoCampaignService.campaignActive;
      this.usersMessageSwitch = this.promoCampaignService.usersMessageActive;
    // }
    // else {
    //   this.campaignForm.patchValue({
    //     campaignActive: false,
    //     usersMessageActive: false,
    //     userMessage: "",
    //     // conversionFactor: 0
    //   });
    // }
  }

  async onSubmit(): Promise<void> {
    if (this.campaignForm.valid) {
      let campaignSettingsObject: any = {
        campaignActive: this.campaignForm.value.campaignActive, 
        usersMessageActive: this.campaignForm.value.usersMessageActive,
        userMessage: this.campaignForm.value.userMessage ? this.campaignForm.value.userMessage : "",
        // conversionFactor: this.campaignForm.value.conversionFactor ? this.campaignForm.value.conversionFactor: 0
      }
      let result = await this.mongoService.savePromoCampaignSettingsData(campaignSettingsObject);
      if(result.status === 200){
        this.promoCampaignService.campaignActive =  this.campaignForm.value.campaignActive;
        this.promoCampaignService.usersMessageActive =  this.campaignForm.value.usersMessageActive;
        this.promoCampaignService.userMessage =  this.campaignForm.value.userMessage;
        this.showInfoDialog(this.saveDataTitle, this.saveDataOkMessage);
      } else {
        this.showInfoDialog(this.saveDataTitle, this.saveDataErrorMessage);
      }
    }
  }

  toggleStartCampaignSwitch() {
    this.startCampaignSwitch = !this.startCampaignSwitch;
    if (this.startCampaignSwitch)
      this.showInfoDialog(this.startCampaignTitle, this.startCampaignMessage);
    else
      this.showInfoDialog(this.stopCampaignTitle, this.stopCampaignMessage);
  }

  toggleShowMessageForUsersSwitch() {
    this.usersMessageSwitch = !this.usersMessageSwitch;
    if (this.usersMessageSwitch)
      this.showInfoDialog(this.messageForUsersTitle, this.startMessageForUsersMessage);
    else
      this.showInfoDialog(this.messageForUsersTitle, this.stopMessageForUsersMessage);
  }

  private showInfoDialog(title: string, message: string): void {
    this.dialog.open(InfoDialogComponent, {
      disableClose: true, data: {
        title: title,
        message: message,
        isErrorInfo: false,
        okFunc: null
      }
    });
  }

  // toggleConversionFactorEdit(){
  //   this.conversionFactorEdit = !this.conversionFactorEdit;
  //   if (this.conversionFactorEdit) {
  //       this.campaignForm.get('conversionFactor')?.enable();
  //     } else {
  //     this.campaignForm.get('conversionFactor')?.disable();
  //   }
  // }

  public redirectToAdministrationScreen() : void {
    this.navigator.redirectToAdministrationScreen();
  }

  async countRanking(){
    this.isSpinnerVisible = true;
    await this.rankingService.prepareRankng();
    this.dialog.open(InfoDialogComponent, {
      disableClose: true, data: {
        title: "Ranking",
        message: "Zakończono obliczanie rankingu",
        isErrorInfo: false,
        okFunc: null
      }
    });
    this.isSpinnerVisible = false;
  }
}
