<div class="paginator-container">
    <div class="wrappable-container">
        <div class="page-size-selection-container">
            <span>Liczba:</span>
            <select class="items-per-page-select" [(ngModel)]="pageSize" name="pageSizeSelection">
                <option [value]="5">5</option>
                <option [value]="10">10</option>
                <option [value]="20">20</option>
                <option [value]="50">50</option>
                <option [value]="100">100</option>
            </select>
        </div>
        <div class="navigation-wrapper">
            <div class="navigation-buttons-container">
                <span role="button" tabindex="0" (click)="goToFirstPage()" style="border-right:none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-bar-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M11.854 3.646a.5.5 0 0 1 0 .708L8.207 8l3.647 3.646a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708 0zM4.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5z"/>
                    </svg>
                </span>
                <span role="button" tabindex="0" (click)="goToPreviousPage()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                    </svg>
                </span>
                <div *ngIf="!isLowerBageButtonEnabled" class="disabled-backdrop"></div>
            </div>
            <div class="page-number-container">
                <span>{{ pageNumber }}/{{ pagesAmount }}</span>
            </div>
            <div class="navigation-buttons-container">
                <span role="button" tabindex="0" (click)="goToNextPage()" style="border-right:none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </span>
                <span role="button" tabindex="0" (click)="goToLastPage()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-bar-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.146 3.646a.5.5 0 0 0 0 .708L7.793 8l-3.647 3.646a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708 0zM11.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5z"/>
                    </svg>
                </span>
                <div *ngIf="!isHigherPageButtonEnabled" class="disabled-backdrop"></div>
            </div>
        </div>
    </div>
</div>