<div class="application-view-main-container">
    <div class="application-view-title">Punktacja</div>
    <div class="main-container">
        <div class="score-row-header">
            <div>Nazwa urządzenia</div>
            <div>Liczba przypisanych punktów</div>
        </div>
        <div *ngFor="let score of scoresData" class="score-row">
            <div>{{score.productName}}</div>
            <div>{{score.score}}</div>
        </div>
    </div>
    <div class="return-button-container" style="padding-top: 1rem;">
        <button type="button" class="btn btn-sm btn-secondary btn-secondary-custom"
          (click)="redirectToAdministrationScreen()">Wróć</button>
      </div>
</div>