import { Component } from '@angular/core';
import { OrderedBenefitsReportService } from 'src/app/reports/ordered-benefits-report.service';
import { OrderlessUsersReportService } from 'src/app/reports/orderless-users-report.service';
import { OrdersReportService } from 'src/app/reports/orders-report.service';
import { RankingReportService } from 'src/app/reports/ranking-report.service';
import { UnregisteredUsersReportService } from 'src/app/reports/unregistered-users-report.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-reports-view',
  templateUrl: './reports-view.component.html',
  styleUrls: ['./reports-view.component.css']
})
export class ReportsViewComponent {

  public isSpinnerVisible: boolean = false;
  public rankingReportTitle: string = 'Ranking';
  public rankingReportDescription: string = 'Raport przedstawia ranking ' +
    'uczestników posegregowanych według ilości zgromadzonych punktów. ' +
    'Uczestnicy z tą samą ilością punktów posegregowani są według najstarszej daty rejestracji urządzenia.';
  public ordersReportTitle: string = 'Raport zamówień';
  public ordersReportDescription: string = 'Raport ten generuje zestawienie ' +
    'przedstawiające jaki użytkownik zamówił jakie nagrody. Zestawienie zawiera ' +
    'rodzaj i ilośc zamówionych nagród przez danego użytkownika.';
  public usersWithoutOrderReportTitle: string = 'Raport osób bez zamówień';
  public usersWithoutOrderReportDescription: string = 'Raport ten generuję listę ' +
    'osób, które na ten moment nie dokonały jeszcze żadnego zamówienia nagród.';
  public orderedBenefitsReportTitle: string = 'Raport zamówionych nagród';
  public orderedBenefitsReportDescription: string = 'Raport ten generuje ilościowe ' +
    'zestawienie zamówionych nagród bez uwzględnienia jaka osoba zamówiła jakie ' +
    'nagrody.';

  public unregisteredUsersReportTitle: string = 'Raport niezarejestrowanych użytkowników';
  public unregisteredUsersReportDescription: string = 'Raport ten generuje ' +
  'zestawienie adresów email użytkowników którzy nie dokonali jeszcze rejestracji.';

  constructor(
    private navigator: NavigatorService,
    private ordersReportService: OrdersReportService,
    private orderlessUsersReportService: OrderlessUsersReportService,
    private orderedBenefitsReportService: OrderedBenefitsReportService,
    private rankingReportService: RankingReportService,
    private unregisteredUsersReportService: UnregisteredUsersReportService,
    public userDataService: UserDataService,
  ) {}

  public redirectToAdministrationScreen() : void {
    this.navigator.redirectToAdministrationScreen();
  }

  public async generateRanking() : Promise<void> {
    this.isSpinnerVisible = true;
    await this.rankingReportService.generateRanking();
    this.isSpinnerVisible = false;
  }

  public async generateOrdersReport() : Promise<void> {
    this.isSpinnerVisible = true;
    await this.ordersReportService.generateReportData();
    this.isSpinnerVisible = false;
  }

  public async generateUsersWithoutOrderReport() : Promise<void> {
    this.isSpinnerVisible = true;
    await this.orderlessUsersReportService.generateReport();
    this.isSpinnerVisible = false;
  }

  public async generateOrderedBenefitsReport() : Promise<void> {
    this.isSpinnerVisible = true;
    await this.orderedBenefitsReportService.generateReport();
    this.isSpinnerVisible = false;
  }

  public async generateUnregisteredUsersReport() : Promise<void> {
    this.isSpinnerVisible = true;
    await this.unregisteredUsersReportService.generateReport();
    this.isSpinnerVisible = false;
  }
}
