import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class MongoService {

  constructor(
    private apiService: ApiService
  ) { }

  //admins
  public async getAllAdminData(): Promise<any> {
    return await this.apiService.getCollectionData("admins");
  }

  public async getAdminData(adminId: string): Promise<any> {
    return await this.apiService.getDocumentsByPropertyValue("admins", "adminId", adminId);
  }

  //benefits
  public async getAllBenefitsData(): Promise<any> {
    return await this.apiService.getCollectionData("benefits");
  }

  //orders
  public async getUsersOrders(): Promise<any> {
    return await this.apiService.getCollectionData("usersOrders");
  }

  public async getUserOrdersByEmail(userEmail: string): Promise<any> {
    return await this.apiService.getUserOrdersByEmail(userEmail);
  }

  public async addUserOrder(orderObject: any): Promise<any> {
    return await this.apiService.addObjectToCollection("usersOrders", orderObject);
  }

  //scores
  public async getAllScoresData(): Promise<any> {
    return await this.apiService.getCollectionData("scores");
  }

  public async addScore(scoreObject: any): Promise<any> {
    return await this.apiService.addObjectToCollection("scores", scoreObject);
  }

  public async deleteScoringData(): Promise<any> {
    return await this.apiService.deleteCollectionData("scores");
  }

  //promoCampaign
  public async getPromoCampaignSettingsData(): Promise<any> {
    return await this.apiService.getCollectionData("promoCampaign");
  }

  public async savePromoCampaignSettingsData(campaignSettingsObject: any): Promise<any> {
    return await this.apiService.replaceCollectionData("promoCampaign", campaignSettingsObject);
  }

  //usersData
  public async getAllUsersData(): Promise<any> {
    return await this.apiService.getCollectionData("usersData");
  }

  public async deleteUsersData(): Promise<any> {
    return await this.apiService.deleteCollectionData("usersData");
  }

  public async addUsersData(usersDataObjects: Array<any>): Promise<any> {
    return await this.apiService.addObjectsToCollection("usersData", usersDataObjects);
  }

  public async clearWinnersStatus(): Promise<any> {
    return await this.apiService.clearWinnersQualifiedStatus();
  }

  public async updateWinnersStatus(winnersList: Array<string>): Promise<any> {
    return await this.apiService.updateWinnersQualifiedStatus(winnersList);
  }

  public async getUserData(userId: any): Promise<any> {
    return await this.apiService.getDocumentById("usersData",userId);
  }

  public async getUserDataByEmail(userEmail: any): Promise<any> {
    return await this.apiService.getUserDataByEmail(userEmail);
  }

  public async updateUserData(userData: any): Promise<any> {
    return await this.apiService.updateUserData(userData);
  }

  public async registerUserData(userData: any): Promise<any> {
    return await this.apiService.registerUserData(userData);
  }

  //prizes data

  public async clearPrizesData(): Promise<any> {
    return await this.apiService.deleteCollectionData("prizes");
  }

  public async setPrizes(prizesArr: any): Promise<any> {
    return await this.apiService.addObjectsToCollection("prizes", prizesArr);
  }

  public async getAllPrizesData(): Promise<any> {
    return await this.apiService.getCollectionData("prizes");
  }
}
