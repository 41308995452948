import { Injectable } from '@angular/core';
import { UserDataService } from '../services/user-data.service';
import { ReportSaveService } from './report-save.service';
import { RankingService } from '../services/ranking.service';

@Injectable({
  providedIn: 'root'
})
export class RankingReportService {

  constructor(
    public userDataService: UserDataService,
    private savingService: ReportSaveService,
    private rankingService: RankingService
  ) { }

  public async generateRanking() : Promise<void> {
    try {
      const allUsersData = await this.userDataService.getAllExistingUsersData();
      const rankingArray = await this.rankingService.prepareRankingArray(allUsersData);
      const reportData = this.generateReportContent(rankingArray);
      this.save(reportData);
    } catch(e) {
      console.warn(e);
    }
  }

  private generateReportContent(rankingArray: Array<any>) : Array<Array<any>> {
    const result: Array<Array<any>> = [];
    result.push(this.generateReportHeader());
    result.push(['']);
    let iterator = 1;
    rankingArray.forEach(u => {
      result.push([u.userEmail, u.points, iterator, u.prizeAmount, u.companyName, u.firstName, u.lastName, u.phoneNumber, u.voivodeship]);
      iterator++;
    });
    iterator = 1;
    return result;
  }

  private generateReportHeader() : Array<string> {
    return ['Adres email', 'Liczba punktów', 'Pozycja w rankingu', 'Wartość nagrody', 'Nazwa firmy', 'Imię', 'Nazwisko', 'Nr telefonu', 'Województwo'];
  }

  private save(data: Array<Array<any>>) : void {
    this.savingService.saveXlsxReport(data, 'Ranking.xlsx', 'Raport')
  }
}