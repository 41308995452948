import { Injectable } from '@angular/core';
import { ExcelReaderService } from './excel-reader.service';
import { PrizesService } from '../services/prizes.service';

@Injectable({
  providedIn: 'root'
})
export class PrizesLoaderService {

  constructor(
    private excelReader: ExcelReaderService,
    private prizesService: PrizesService
  ) { }

  public async executePrizesReplacement(excelFile: any) : Promise<boolean>{
    try {
      const prizesData = await this.excelReader.parseExcelFile(excelFile);
      const prizesArr = await this.createPrizesArray(prizesData);
      if (prizesArr.length > 0) {
        await this.prizesService.clearPrizesData();
        await this.prizesService.setPrizes(prizesArr);
        return true;
      } else if (prizesArr.length == 0){
        await this.prizesService.clearPrizesData();
        return true;
      }
      else {
        return false;
      }
    } catch (e) {
      console.error(e);
      console.error('Could not update winners status');
      return false;
    }
  }

  private async createPrizesArray(data:any): Promise<any>{
    let prizesArr:Array<any> = []
    data.forEach((prize:any) => prizesArr.push({
      place: prize.place,
      prizeAmount: prize.prizeAmount
    }));
    return prizesArr;
  }
}
