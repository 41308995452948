<div class="application-view-main-container">
  <div class="points-amount-label points-amount-label-error"
    *ngIf="didUserPlaceOrder; else noOrderPlacedYet">Na Twoim koncie dokonano już zamówienia.</div>
  <ng-template #noOrderPlacedYet>
    <div class="points-amount-label">Stan konta: {{ userPointsAmount }} zł.</div>
  </ng-template>
  <div class="benefit-information-container" *ngIf="isBenefitDataAvailable; else failedToLoadBenefit">
    <img [src]="benefitData.imageUrl"/>
    <div class="information-container">
      <div class="benefit-info-wrapper">
        <div>
          <span>Nazwa nagrody: {{ benefitData.name }}</span>
        </div>
        <div>
          <span>Termin ważności: {{ benefitData.validityPeriodMonths }} miesięcy.</span>
        </div>
        <div class="price-selection-wrapper">
          <span>Wybierz wartość</span>
          <select class="form-control" [(ngModel)]="selectedPrice" [disabled]="didUserPlaceOrder" (change)="selectedPriceChange()">
            <option *ngFor="let price of availablePrices" [value]="price" [selected]="selectedPrice === price">{{ price }} zł.</option>
          </select>
        </div>
        <div class="price-selection-wrapper">
          <span>Wybierz ilość</span>
          <input class="form-control" type="number" min="1" [max]="maximumAmountToAddToCart"
            step="1" [(ngModel)]="selectedAmount" [disabled]="!selectedPrice || didUserPlaceOrder"/>
        </div>
      </div>
      <div class="card-buttons-wrapper">
        <button type="button" class="btn btn-sm btn-secondary btn-secondary-custom"
          (click)="redirectToBenefitsScreen()">Katalog nagród</button>
        <button [disabled]="!canAddToCart || !selectedPrice || !this.promoCampaignService.campaignActive" class="btn btn-sm btn-primary btn-primary-custom"
          (click)="addToCart()">Dodaj do koszyka</button>
      </div>
    </div>
  </div>
  <div class="benefit-description-container">
    <span>{{ benefitDescription }} <a [href]="hyperlink" *ngIf="hasHyperlink">{{ benefitData.hyperlink }}</a><span *ngIf="hasHyperlink">.</span></span>
  </div>
  <ng-template #failedToLoadBenefit>
    <div>Błąd. Nie udało się wczytać danych magrody.</div>
  </ng-template>
</div>