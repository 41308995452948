import { Injectable } from '@angular/core';
import { UserOrdersService } from '../services/user-orders.service';
import { ReportSaveService } from './report-save.service';
import { UserDataService } from '../services/user-data.service';

@Injectable({
  providedIn: 'root'
})

export class OrderlessUsersReportService {

  constructor(
    private ordersService: UserOrdersService,    
    private userDataService: UserDataService,
    private savingService: ReportSaveService) {
  }

  public async generateReport() : Promise<void> {
    try {
      const allOrders = await this.ordersService.getAllExistingOrders();
      const usersData = await this.userDataService.getAllExistingUsersData();
      const usersWithoutOrder = this.getUsersWithoutOrder(allOrders, usersData);
      const reportData = this.generateReportContent(usersWithoutOrder);
      this.save(reportData);
    } catch(e) {
      console.warn(e);
    }
  }

  private getUsersWithoutOrder(allOrders: Array<any>, allUsersAdditionalData: Array<any>) : Array<any> {
    const usersWithouOrder: Array<any> = [];
    allUsersAdditionalData.forEach(d => {
      if (!allOrders.some(o => o.userEmail === d.userEmail)) {
        usersWithouOrder.push(d);
      }
    });
    return usersWithouOrder;
  }

  private generateReportContent(usersWithoutOrder: Array<any>) : Array<Array<any>> {
    const result: Array<Array<any>> = [];
    result.push(this.generateReportHeader());
    result.push(['']);
    usersWithoutOrder.forEach(u => {
      result.push([u.firstName, u.lastName, u.userEmail, u.prizeAmount, u.phoneNumber,
        u.streetName, u.buildingNumber, u.postalCode, u.city, u.voivodeship, u.companyName]);
    });
    return result;
  }

  private generateReportHeader() : Array<string> {
    return ['Imię', 'Nazwisko', 'Adres email', 'Przydzielone zł', 'Numer telefonu',
      'Ulica', 'Numer budynku/mieszkania', 'Kod pocztowy', 'Miasto', 'Województwo', 'Nazwa firmy'];
  }

  private save(data: Array<Array<any>>) : void {
    this.savingService.saveXlsxReport(data, 'Raport osób bez zamówień.xlsx', 'Raport')
  }
}
