<!-- <button type="button" (click)="openPasswordRequirementsDialog()">OPEN</button> -->

<div class="login-form-container" *ngIf="!formSubmitted; else elseBlock">
  <form [formGroup]="resetPasswordForm" (ngSubmit)="confirm()">
    <div class="login-form-items-container">
      <div class="login-form-title-container">
        <span>Reset hasła</span>
      </div>
      <div class="login-form-input-description-container">
        <span>Podaj adres email przypisany do konta</span>
        <input type="email" class="form-control" formControlName="email" />
        <span *ngIf="formSubmitted && resetPasswordForm.controls.email.errors" class="input-error">Adres email jest
          wymagany</span>
      </div>
      <div class="login-dorm-error-info-container" *ngIf="invalidAuthenticationData">
        <span>Niepoprawny adres email</span>
      </div>
      <div class="login-form-submit-button-container">
        <button class="btn btn-primary btn-primary-custom">Resetuj</button>
      </div>
    </div>
  </form>
</div>
<ng-template #elseBlock>
  <div class="application-view-main-container" *ngIf="passwordResetResult">
    <div class="texts-container">
      <p class="landing-page-text landing-page-text-big text-orange text-bold">
        Na adres email {{ resetPasswordForm.controls.email.value }} wysłano wiadomość zawierającą link umożliwiający reset hasła.</p>
    </div>
  </div>
  <div class="application-view-main-container" *ngIf="!passwordResetResult">
    <div class="texts-container">
      <p class="landing-page-text landing-page-text-big text-orange text-bold">Konto z przypisanym adresem {{
        resetPasswordForm.controls.email.value }} nie istnieje.</p>
      <p class="landing-page-text text-green">Sprawdź poprawność wprowadzonego adresu lub skontaktuj się z pomocą</p>
      <p class="landing-page-text text-green">kontakt&#64;panasonic-store.pl</p>
    </div>
  </div>
</ng-template>
<waiting-spinner *ngIf="isSpinnerVisible" [text]="'Resetuje...'"></waiting-spinner>