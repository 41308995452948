import { Injectable } from '@angular/core';
import { MongoService } from './mongo.service';

@Injectable({
  providedIn: 'root'
})
export class PrizesService {

  constructor(
    private mongoService: MongoService
  ) { }

  public async clearPrizesData(){
    await this.mongoService.clearPrizesData();
  }

  public async setPrizes(prizesData: Array<string>) : Promise<any> {
    return await this.mongoService.setPrizes(prizesData);
  }

  public async getAllPrizes(): Promise<Array<any>> {
    return await this.mongoService.getAllPrizesData();
  }
}
