<div class="icon-card-wrapper">
  <div class="icon-card-border-container icon-card-border-container-error">
    <div class="icon-card-icon-container"></div>
    <div class="action-elements-container">
      <div class="action-info-data-container">
        <span class="bolded-text app-card-text-underline">{{ actionTitle }}</span>
        <p class="action-description">{{ actionDescription }}</p>
      </div>
      <div class="execute-action-button-container">
        <button type="button" class="btn btn-sm btn-primary btn-primary-custom"
          (click)="handleActionExecution()">Wybierz plik</button>
        <input #fileInput type="file" onclick="this.value = null" hidden (change)="handleOpenedFile($event)" accept=".xlsx"/>
      </div>
    </div>
  </div>
</div>