import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private cartContent: Array<any> = [];
  private userPointsAmount: number = 0;

  public get userPoints() {
    return this.userPointsAmount;
  }

  public get userAvailablePoints() {
    return this.userPoints - this.countCartItemTotalPrice();
  }

  public get cartItems() {
    return this.cartContent;
  }

  public get totalItemsQuantity() {
    return this.countCartTotalItemsQuantity();
  }

  constructor() { 
    this.resetCartState();
  }

  public setUserPointsAmount(pointsAmount: number) : void {
    this.userPointsAmount = pointsAmount;
  }

  public resetCartState() : void {
    this.initializeCart();
  }

  public getCartTotalPrice() : number {
    return this.countCartItemTotalPrice();
  }

  public addItemToCart(benefitData: any, selectedPrice: number, quantity: number = 1) : void {
    if (selectedPrice > 0) {
      const existingBenefit = this.getCartBenefit(benefitData?._id, selectedPrice);
      if (existingBenefit) {
        this.increaseBenefitAmount(benefitData?._id ?? '', selectedPrice, quantity);
      } else {
        this.addNewBenefitToCart(benefitData, selectedPrice, quantity);
      }
    } else {
      throw new Error("Cannot add to cart item with price less than 0.");
    }
  }

  public removeItemFromCart(benefitId: string, selectedPrice: number) : void {
    this.cartContent = this.cartContent
      ?.filter(b => b.benefitId !== benefitId && b.unitPrice !== selectedPrice);
  }

  public increaseBenefitAmount(benefitId: any, price: number, quantity: number = 1) : void {
    const existingBenefit = this.getCartBenefit(benefitId, price);
    if (existingBenefit) {
      existingBenefit.quantity += quantity;
    }
  }

  public decreaseBenefitAmount(benefitId: string, price: number) : void {
    const existingBenefit = this.getCartBenefit(benefitId, price);
    if (existingBenefit) {
      existingBenefit.quantity -= 1;
      if (existingBenefit.quantity < 1) {
        this.removeItemFromCart(benefitId, price);
      }
    }
  }

  private getCartBenefit(benefitId: string, price: number) : any {
    return  this.cartContent
      ?.find(b => b.benefitId === benefitId && b.unitPrice === price)
      ?? null;
  }

  private addNewBenefitToCart(benefitData: any, price: number, quantity: number) : void {
    if (benefitData?._id?.length > 0 && price > 0) {
      this.cartContent.push({
        benefitId: benefitData._id,
        unitPrice: price,
        imageUrl: benefitData.imageUrl,
        name: benefitData.name,
        quantity: quantity
      });
    }
  }

  private initializeCart() : void {
    this.cartContent = [];
    this.userPointsAmount = 0;
  }

  private countCartItemTotalPrice() : number {
    let sum: number = 0;
    this.cartContent?.forEach(p => sum += p.unitPrice * p.quantity);
    return sum;
  }

  private countCartTotalItemsQuantity() : number {
    let sum: number = 0;
    this.cartContent?.forEach(p => sum += p.quantity);
    return sum;
  }
}
