import { Component, OnInit } from '@angular/core';
import { MongoService } from 'src/app/services/mongo.service';
import { NavigatorService } from 'src/app/services/navigator.service';

@Component({
  selector: 'view-rewards',
  templateUrl: './view-rewards.component.html',
  styleUrl: './view-rewards.component.css'
})
export class ViewRewardsComponent implements OnInit {
  rewardsData: any[] = [];

  constructor(
    private navigator: NavigatorService,
    private mongoService: MongoService
  ){}

  public async ngOnInit(): Promise<void> {
    this.rewardsData = await this.mongoService.getAllPrizesData();
  }

  public redirectToAdministrationScreen() : void {
    this.navigator.redirectToAdministrationScreen();
  }
}
