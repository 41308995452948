<div *ngFor="let item of data" class="row-item" (click)="itemClicked(item)"  tabindex="0">
    <div class="main-container">
        <div class="name-actions-container">
            <div class="name-container">
                <span class="item-title-clickable">{{item.userEmail}}</span>
            </div>
            <div class="company-points-container">
                <div class="company-container">
                    <span class="item">Firma: </span>
                    &nbsp;
                    <span class="item">{{item.companyName | truncate:20}}</span>
                </div>
                <div class="points-container">
                    <span class="item">Punkty: </span>
                    &nbsp;
                    <span class="item">{{item.points}}</span>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <div *ngFor="let item of data" class="row-item" (click)="itemClicked(item)"  tabindex="0" (keypress)="keyPress($event,item,'click')">
    <div class="main-container">
        <div class="content-container" [ngClass]="{'content-container-center':!centered}">
            <div class="image-container">
                <img class="item-image" [src]="getImgSrc(item)" onerror="this.src=`assets/file.png`; this.style= `filter: var(--black-to-yellow-filter);`" alt="{{ item?.documentData?.documentName }}"
                >
            </div>
            <div class="data-container">
                <span *ngIf="options.clickable" class="item-title-clickable">{{ item?.documentData?.documentName }}</span>
                <span *ngIf="!options.clickable" class="item-title">{{ item?.documentData?.documentName }}</span>
            </div>
        </div>
        <div class="actions-container">
            <span role="button" tabindex="0" (keypress)="keyPress($event,item,'compress')" title="{{translation.compressFile}}" *ngIf="options.compressButton" class="header-button" (click)="compressModel(item)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-arrows-angle-contract" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                        d="M.172 15.828a.5.5 0 0 0 .707 0l4.096-4.096V14.5a.5.5 0 1 0 1 0v-3.975a.5.5 0 0 0-.5-.5H1.5a.5.5 0 0 0 0 1h2.768L.172 15.121a.5.5 0 0 0 0 .707zM15.828.172a.5.5 0 0 0-.707 0l-4.096 4.096V1.5a.5.5 0 1 0-1 0v3.975a.5.5 0 0 0 .5.5H14.5a.5.5 0 0 0 0-1h-2.768L15.828.879a.5.5 0 0 0 0-.707z" />
                </svg>
            </span>
            <span role="button" tabindex="0" (keypress)="keyPress($event,item,'split')" title="{{translation.splitFile}}" *ngIf="options.splitButton" class="header-button" (click)="splitModel(item)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-angle-expand"
                    viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                        d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707z" />
                </svg>
            </span>
            <span role="button" tabindex="0" (keypress)="keyPress($event,item,'edit')" title="{{translation.edit}}" *ngIf="options.editButton" class="header-button" (click)="editItem($event,item)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                </svg>
            </span>
            <span role="button" tabindex="0" (keypress)="keyPress($event,item,'rename')" title="{{translation.rename}}" *ngIf="options.renameButton" class="header-button" (click)="renameItem($event,item)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                </svg>
            </span>
            <span role="button" tabindex="0" (keypress)="keyPress($event,item,'copy')" title="{{translation.copy}}" *ngIf="options.copyButton" class="header-button" (click)="copyItem($event,item)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-files" viewBox="0 0 16 16">
                    <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
                  </svg>
            </span>
            <span role="button" tabindex="0" (keypress)="keyPress($event,item,'delete')" title="{{translation.delete}}" *ngIf="options.deleteButton" class="header-button" (click)="deleteItem($event, item)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
                    viewBox="0 0 16 16">
                    <path
                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path fill-rule="evenodd"
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                </svg>
            </span>
        </div>
    </div>
</div> -->