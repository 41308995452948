import { Component, OnInit } from '@angular/core';
import { MongoService } from 'src/app/services/mongo.service';
import { NavigatorService } from 'src/app/services/navigator.service';

@Component({
  selector: 'scoring-view',
  templateUrl: './scoring-view.component.html',
  styleUrl: './scoring-view.component.css'
})

export class ScoringViewComponent implements OnInit {

  scoresData: any[] = [];

  constructor(
    private navigator: NavigatorService,
    private mongoService: MongoService
  ){}

  public async ngOnInit(): Promise<void> {
    this.scoresData = await this.mongoService.getAllScoresData();
  }

  public redirectToAdministrationScreen() : void {
    this.navigator.redirectToAdministrationScreen();
  }
}
