import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx'; 

@Injectable({
  providedIn: 'root'
})
export class ExcelReaderService {

  constructor() { }

  public parseExcelFile(excelFile: any) : Promise<Array<any>> {
    return new Promise((resolve) => {
      try {
        // this.executeParsing(excelFile, (data) => resolve(data));
        this.executeParsing(excelFile, (data) => {
          const formattedData = this.formatDates(data);
          resolve(formattedData);
        });
      } catch {
        resolve([]);
      }
    });
  }

  private executeParsing(excelFile: any, dataLoadedFunc: (data: Array<any>) => void) : void {
    if (excelFile) {
      try {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(excelFile);
        fileReader.onload = () => this.onFileLoad(fileReader, dataLoadedFunc);
      } catch (e) {
        console.error('Could not parse excel file.');
        console.error(e);
      }
    }
  }

  private onFileLoad(fileReader: FileReader, dataLoadedFunc: (data: Array<any>) => void) : void {
    const data = new Uint8Array(fileReader.result as ArrayBufferLike); 
    var array = new Array(); 
    for(var i = 0; i != data.length; ++i) {

      array[i] = String.fromCharCode(data[i]);  
    }
    const bytestring = array.join('');
    const workbook = XLSX.read(bytestring, { type: 'binary' });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const arrayList = XLSX.utils.sheet_to_json(worksheet, { raw: true });
    dataLoadedFunc(arrayList);
  }

  // private formatDates(data: Array<any>): Array<any> {
  //   return data.map(row => {
  //     for (const key in row) {
  //       if (row[key] instanceof Date || !isNaN(Date.parse(row[key]))) {
  //         row[key] = new Date(row[key]);
  //       }
  //     }
  //     return row;
  //   });
  // }

  private formatDates(data: Array<any>): Array<any> {
    return data.map(row => {
      for (const key in row) {
        if (typeof row[key] === 'number' && this.isSerialDate(row[key])) {
          const dateObj = XLSX.SSF.parse_date_code(row[key]);
          row[key] = this.formatDate(dateObj);
        }
      }
      return row;
    });
  }
  
  private isSerialDate(value: number): boolean {
    // Zakładamy, że każda liczba większa niż 40000 to data w formacie Excel
    return value > 40000;
  }

  private formatDate(dateObj: {y: number, m: number, d: number, H: number, M: number}): string {
    const year = String(dateObj.y).slice(-2);
    const month = String(dateObj.m).padStart(2, '0');
    const day = String(dateObj.d).padStart(2, '0');
    const hours = String(dateObj.H).padStart(2, '0');
    const minutes = String(dateObj.M).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }
}
