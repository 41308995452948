import { Injectable } from '@angular/core';
import { BenefitsService } from '../services/benefits.service';
import { UserOrdersService } from '../services/user-orders.service';
import { ReportSaveService } from './report-save.service';

@Injectable({
  providedIn: 'root'
})
export class OrderedBenefitsReportService {

  constructor(
    private ordersService: UserOrdersService,
    private benefitsService: BenefitsService,
    private savingService: ReportSaveService) {
  }

  public async generateReport() : Promise<void> {
    try {
      const allBenefits = await this.benefitsService.getAllExistingBenefits();
      const allOrders = await this.ordersService.getAllExistingOrders();
      const reportDataSource = this.getOrderedBenefitsReportData(allBenefits, allOrders);
      const reportData = this.generateReportContent(reportDataSource);
      this.save(reportData);
    } catch(e) {
      console.warn(e);
    }
  }

  private getOrderedBenefitsReportData(allBenefits: Array<any>, allOrders: Array<any>) : Array<any> {
    const reportData: Array<{ benefitData: any, price: number, quantity: number }> = [];
    allOrders.forEach((order: any) => {
      if (order?.items?.length > 0) {
        order.items.forEach((item: any) => {
          const benefit = this.findBenefit(allBenefits, item.benefitId);
          if (benefit) {
            this.addBenefitToOverallConsumption(benefit, item.unitPrice, item.quantity, reportData);
          } else {
            throw new Error(`Unknown benefit id ${item.benefitId}.`);
          }
        });
      }
    });
    return reportData;
  }

  private findBenefit(allBenefits: Array<any>, benefitId: string) : any {
    return allBenefits?.find(b => b._id === benefitId) ?? null;
  }

  private addBenefitToOverallConsumption(benefit: any, price: number, quantity: number,
      reportData: Array<{ benefitData: any, price: number, quantity: number }>) : void {
    const existingBenefitInData = reportData
      .find(d => d.benefitData._id === benefit._id && d.price === price);
    if (existingBenefitInData) {
      existingBenefitInData.quantity += quantity;
    } else {
      reportData.push({ benefitData: benefit, price: price, quantity: quantity });
    }
  }

  private generateReportContent(reportData: Array<{ benefitData: any, price: number, quantity: number }>) : Array<Array<any>> {
    const result: Array<Array<any>> = [];
    result.push(this.generateReportHeader());
    result.push(['']);
    reportData.forEach(d => {
      result.push([d.benefitData.name, d.benefitData.validityPeriodMonths, d.price, d.quantity, d.price * d.quantity]);
    });
    return result;
  }

  private generateReportHeader() : Array<string> {
    return ['Nazwa nagrody', 'Ważność w miesiącach', 'Cena jednostkowa', 'Ilość', 'Iloczyn'];
  }

  private save(data: Array<Array<any>>) : void {
    this.savingService.saveXlsxReport(data, 'Raport zamówionych nagród.xlsx', 'Raport')
  }
}
