import { Injectable } from '@angular/core';
import { BenefitsService } from '../services/benefits.service';
import { UserOrdersService } from '../services/user-orders.service';
import { ReportSaveService } from './report-save.service';
import { UserDataService } from '../services/user-data.service';

@Injectable({
  providedIn: 'root'
})

export class OrdersReportService {

  private noDataString: string = '';

  constructor(
    private benefitsService: BenefitsService,
    private ordersService: UserOrdersService,
    private userDataService: UserDataService,
    private savingService: ReportSaveService,
  ) {}

  public async generateReportData() : Promise<void> {
    try {
      const allOrders = this.orderOrdersByEmailAddress(
        await this.ordersService.getAllExistingOrders());
      const allBenefits = await this.benefitsService.getAllExistingBenefits(true);
      const usersData = await this.userDataService.getAllExistingUsersData();
      const data = this.generateData(allOrders, allBenefits, usersData);
      const flattenedData = this.flattenGeneratedReportData(data);
      this.save(flattenedData);
    } catch(e) {
      console.warn(e);
    }    
  }

  private save(data: Array<Array<any>>) : void {
    this.savingService.saveXlsxReport(data, 'Raport Zamówień.xlsx', 'Raport')
  }

  private generateData(allOrders: Array<any>, allBenefits: Array<any>,
      allUsersAdditionalData: Array<any>) : Array<any> {
    const resultData: Array<any> = [];
    allOrders?.forEach(o => {
      resultData.push(this.generateDataForSingleOrder(o,
        allBenefits, allUsersAdditionalData));
    });
    return resultData;
  }

  private generateDataForSingleOrder(order: any, allBenefits: Array<any>,
      allUsersAdditionalData: Array<any>) : any {
    const userAdditionalData = this.findUserAdditionalData(
      order?.userEmail ?? '', allUsersAdditionalData);
    const orderHeaderData = this.generateSingleOrderHeader(order, userAdditionalData);
    const benefitsData = this.generateOrderBenefitsData(order, allBenefits);
    return {
      header: orderHeaderData,
      items: benefitsData
    }
  }

  private findUserAdditionalData(userEmail: string,
      allUsersAdditionalData: Array<any>) : any {
    return allUsersAdditionalData?.find(d => d?.userEmail === userEmail)
        ?? null;
  }

  private generateSingleOrderHeader(order: any, userAdditionalData: any) : any {
    let options = {
      timeZone: 'Europe/Warsaw',
      year: 'numeric' as const,
      month: '2-digit' as const,
      day: '2-digit' as const,
      hour: '2-digit' as const,
      minute: '2-digit' as const,
      second: '2-digit' as const
    };
    let formatter = new Intl.DateTimeFormat('pl-PL', options);

    return {
      userFirstName: userAdditionalData?.firstName ?? this.noDataString,
      userLastName: userAdditionalData?.lastName ?? this.noDataString,
      userPoints: userAdditionalData?.prizeAmount ?? -1,
      userEmail: order?.userEmail ?? this.noDataString,
      orderDate: formatter.format(new Date(order?.orderDate)), //.seconds > 0 ? this.getDateFromEpochPlusSeconds(order.orderDate.seconds) : new Date(),
      pointsSpent: order?.priceSum ?? -1,
      userPersonalAddress: this.getUserPersonalAddress(userAdditionalData),
      userCompanyName: userAdditionalData?.companyName ?? this.noDataString,
      phoneNumber: userAdditionalData?.phoneNumber ?? this.noDataString
    };
  }

  private getUserPersonalAddress(userAdditionalData: any) : string {
    let address: string = '';
    if (userAdditionalData?.streetName?.length > 0) {
      address += `ul. ${userAdditionalData.streetName}`;
      address += ` ${userAdditionalData?.buildingNumber ?? this.noDataString}`;
      address += `, ${userAdditionalData?.postalCode ?? this.noDataString}`;
      address += `, ${userAdditionalData?.city ?? this.noDataString}`;
      address += `, ${userAdditionalData?.voivodeship ?? this.noDataString}`;
    } else {
      address += `${userAdditionalData?.postalCode ?? this.noDataString}`;
      address += `, ${userAdditionalData?.city ?? this.noDataString}`;
      address += `, ${userAdditionalData?.voivodeship ?? this.noDataString}`;
      address += ` ${userAdditionalData?.buildingNumber ?? this.noDataString}`;
    }
    return address;
  }

  private generateOrderBenefitsData(order: any, allBenefits: Array<any>) : Array<any> {
    const benefitsData: Array<any> = [];
    order?.items?.forEach((i: any) => {
      benefitsData.push(this.generateOrderBenefitData(i, allBenefits));
    });
    return benefitsData;
  }

  private generateOrderBenefitData(orderItem: any, allBenefits: Array<any>) : any {
    const benefitData = this.findBenefit(orderItem?.benefitId ?? '', allBenefits);
    return {
      name: benefitData?.name ?? this.noDataString,
      unitPrice: orderItem?.unitPrice ?? -1,
      quantity: orderItem?.quantity ?? -1,
      partialPrice: (orderItem?.unitPrice ?? 0) * (orderItem?.quantity ?? 0)
    }
  }

  private findBenefit(benefitId: string, allBenefits: Array<any>) : any {
    return allBenefits?.find(b => b._id === benefitId) ?? null;
  }

  private flattenGeneratedReportData(ordersData: Array<any>) : Array<Array<any>> {
    const flattenedData: Array<any> = [];
    flattenedData.push(...this.getReportDataDescription());
    ordersData?.forEach(d => {
      flattenedData.push(['']);
      flattenedData.push([d.header.userEmail, d.header.userFirstName, d.header.userLastName,
        d.header.userPoints, d.header.pointsSpent, d.header.orderDate.toString(),
        d.header.phoneNumber, d.header.userPersonalAddress, d.header.userCompanyName]);
      d?.items.forEach((i: any) => 
        flattenedData.push(['', i.name, i.unitPrice, i.quantity, i.partialPrice]));
    });
    return flattenedData;
  }

  private getReportDataDescription() : Array<Array<any>> {
    return [
      ['Adres email', 'Imię', 'Nazwisko', 'Dostępne zł', 'Wydane zł', 'Data zamówienia',
        'Numer telefonu' ,'Adres osoby', 'Nazwa firmy'],
      ['', 'Nazwa nagrody', 'Koszt jednostrkowy', 'Ilość', 'Suma w pozycji'],
      ['']
    ];
  }

  private getDateFromEpochPlusSeconds(secondsAmount: number) : Date {
    const date = new Date(1970, 0, 1);
    date.setSeconds(secondsAmount);
    return date;
  }

  private orderOrdersByEmailAddress(orders: Array<any>) : Array<any> {
    return orders.sort((a, b) => {
      if (a.userEmail.toLowerCase() < b.userEmail.toLowerCase()) {
        return -1;
      }
      if (a.userEmail.toLowerCase() > b.userEmail.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }
}
