import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CartService } from 'src/app/services/cart.service';
import { YesNoDialogComponent } from '../../common/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'cart-item-presenter',
  templateUrl: './cart-item-presenter.component.html',
  styleUrls: ['./cart-item-presenter.component.css']
})
export class CartItemPresenterComponent {

  @Input() cartItemData: any;

  public get totalPrice() {
    return (this.cartItemData?.quantity ?? 0) * (this.cartItemData?.unitPrice ?? 0);
  }

  public get canIncreaseAmount() {
    return (this.cartService?.userAvailablePoints ?? 0) - (this.cartItemData?.unitPrice ?? 0) >= 0;
  }

  public get canDecreaseAmount() {
    return this.cartItemData?.quantity > 1;
  }

  constructor(private cartService: CartService,
    private dialog: MatDialog) { }

  public increaseAmount() : void {
    this.cartService.increaseBenefitAmount(this.cartItemData?.benefitId ?? '', this.cartItemData?.unitPrice ?? 0);
  }

  public decreaseAmount() : void {
    this.cartService.decreaseBenefitAmount(this.cartItemData?.benefitId ?? '', this.cartItemData?.unitPrice ?? 0);
  }

  public remove() : void {
    this.dialog.open(YesNoDialogComponent, { disableClose: true, data: {
      title: 'Potwierdź usunięcie',
      message: `Czy na pewno chcesz usunąć nagrodę '${this.cartItemData.name}' w ilości ${this.cartItemData.quantity} sztuk z koszyka?`,
      confirmFunction: this.removeCartItem
    } });
  }

  private removeCartItem = () : void => {
    this.cartService.removeItemFromCart(this.cartItemData?.benefitId ?? '', this.cartItemData?.unitPrice ?? 0);
  }
}
