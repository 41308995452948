import * as XLSX from 'xlsx'; 
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportSaveService {

  constructor() { }

  public saveXlsxReport(data: Array<Array<any>>,
      fileName: string, sheetName: string) : void {
    const ws: XLSX.WorkSheet =XLSX.utils.aoa_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, fileName);
  }
}
