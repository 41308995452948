import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'items-list',
  templateUrl: './items-list.component.html',
  styleUrls: ['./items-list.component.css']
})
export class ItemsListComponent implements OnInit, OnDestroy {

  @Input() data: Array<any> = [];
  @Output() itemClickedEvent = new EventEmitter<any>();
  @Output() deleteItemEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
  }

  deleteItem(event: any, item: any) {
    event.stopPropagation();
    this.deleteItemEvent.emit(item);
  }

  itemClicked(item: any) {
    this.itemClickedEvent.emit(item);
  }
}
