import { Injectable } from '@angular/core';
import { addDoc, collection, deleteDoc, doc, DocumentData, DocumentReference, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { FirebaseInitService } from './firebase-init.service';

@Injectable({
  providedIn: 'root'
})
export class UserAdditionalDataService {

  constructor(private firebaseService: FirebaseInitService) {

  }

  // public async fetchUserAdditionalData(userEmailAddress: string) : Promise<any> {
  //   try {
  //     const userAdditionalDataQuery = query(
  //       collection(this.firebaseService.database, 'usersData'),
  //       where('userEmail', '==', userEmailAddress.toLowerCase()));
  //     const snapshot = await getDocs(userAdditionalDataQuery);
  //     if (snapshot?.docs?.length === 1) {
  //       const userData = snapshot.docs[0].data();
  //       userData._id = snapshot.docs[0].id;
  //       return userData;
  //     }
  //     return null;
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   return null;
  // }

  // public async fetchAllUsersAdditionalData() : Promise<Array<any>> {
  //   try {
  //     const dataQuery = query(collection(this.firebaseService.database, 'usersData'));
  //     const snapshot = await getDocs(dataQuery);
  //     const result: Array<any> = [];
  //     snapshot?.docs?.forEach(d => {
  //       const data = d.data();
  //       data._id = d.id;
  //       result.push(data);
  //     });
  //     return result;
  //   } catch(e) {
  //     console.log(e);
  //     return [];
  //   }
  // }

  public getUserAdditionalFirebaseReference(firebaseEntityId: string) : DocumentReference<DocumentData> | null {
    if (firebaseEntityId?.length > 0) {
      const userAdditionalDataReference = doc(this.firebaseService.database,
        'usersData', firebaseEntityId);
      return userAdditionalDataReference;
    }
    return null;
  }

  public async updateUserAdditionalData(additionalDataDocReference: DocumentReference<DocumentData>,
      data: any) : Promise<boolean> {
    try {
      await updateDoc(additionalDataDocReference, data);
      return true;
    } catch(e) {
      console.log(e);
      return false;
    }
  }

  // public async removeUsersAdditionalData(exceptEmailAddresses: Array<any>) : Promise<void> {
  //   const allUsersAdditionalData = await this.fetchAllUsersAdditionalData();
  //   if (allUsersAdditionalData?.length > 0) {
  //     for (let i = 0; i < allUsersAdditionalData.length; i++) {
  //       if (!exceptEmailAddresses.includes(allUsersAdditionalData[i].userEmail)) {
  //         await deleteDoc(doc(this.firebaseService.database, 
  //           'usersData', allUsersAdditionalData[i]._id));
  //       }
  //     }
  //   }
  // }

  // public async addUserAdditionalData(additionalData: any) : Promise<boolean> {
  //   try {
  //     await addDoc(
  //       collection(this.firebaseService.database, 'usersData'), {
  //         buildingNumber: '',
  //         city: '',
  //         voivodeship: '',
  //         companyAddress: '',
  //         firstName: '',
  //         lastName: '',
  //         points: additionalData.points,
  //         postalCode: '',
  //         streetName: '',
  //         userEmail: additionalData.userEmail.toLowerCase(),
  //         phoneNumber: ''
  //       });
  //     return true;
  //   } catch (e) {
  //     console.error(e);
  //     return false;
  //   }
  // }
}
