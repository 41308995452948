<div class="application-view-main-container">
    <div class="application-view-title">Nagrody</div>
    <div class="main-container">
        <div class="reward-row-header">
            <div>Pozycja</div>
            <div>Nagroda</div>
        </div>
        <div *ngFor="let reward of rewardsData" class="reward-row">
            <div>{{reward.place}}</div>
            <div>{{reward.prizeAmount}}</div>
        </div>
    </div>
    <div class="return-button-container" style="padding-top: 1rem;">
        <button type="button" class="btn btn-sm btn-secondary btn-secondary-custom"
          (click)="redirectToAdministrationScreen()">Wróć</button>
      </div>
</div>
