import { Component, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { YesNoDialogComponent } from '../../common/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'database-management-presenter',
  templateUrl: './database-management-presenter.component.html',
  styleUrls: ['./database-management-presenter.component.css']
})
export class DatabaseManagementPresenterComponent {

  @Input() actionTitle: string = '';
  @Input() actionDescription: string = '';
  @Input() useOpenExcel: boolean = false;
  @Output() executeAction = new EventEmitter<any>()
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement> | null = null;

  constructor(private dialog: MatDialog) {    
  }

  public handleActionExecution() : void {
    this.dialog.open(YesNoDialogComponent, { disableClose: true, data: {
      title: 'Potwierdź akcję',
      message: 'Czy na pewno chcesz przeprowadzić tę akcję?',
      confirmFunction:  this.actionExecutionConfirmed
    } });
  }

  public handleOpenedFile(event: any) : void {
    const file: any = event?.target?.files?.[0];
    if (file) {
      this.executeAction.emit(file);
    }
  }

  private actionExecutionConfirmed = () : void => {
    if (this.useOpenExcel) {
      if (this.fileInput?.nativeElement) {
        this.fileInput.nativeElement.dispatchEvent(new MouseEvent('click', {
          'view': window,
          'bubbles': false,
          'cancelable': true
        }));
      } else {
        console.warn('File input not binded.');
      }
    } else {
      this.executeAction.emit();
    }
  }
}
