import { Injectable } from '@angular/core';
import { User, onAuthStateChanged, getAuth, signInWithEmailAndPassword, signOut,
  updatePassword, updateProfile, sendPasswordResetEmail } from 'firebase/auth';
import { AdministratorManagementService } from './administrator-management.service';
import { CartService } from './cart.service';
import { NavigatorService } from './navigator.service';
import { UserAdditionalDataService } from './user-additional-data.service';
import { UserOrdersService } from './user-orders.service';
import { ScoringService } from './scoring.service';
import { MongoService } from './mongo.service';
import { ApiService } from './api.service';
import { PrizesService } from './prizes.service';
import { RankingService } from './ranking.service';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  private _currentUser: User | null = null;
  private _isCurrentUserAdmin: boolean = false;
  private _isCurrentUserPanasonicAdmin: boolean = false;
  private _userAdditionalData: any = null;

  public get isUserLoggedIn() {
    return this._currentUser !== null;
  }

  public get currentUser() {
    return this._currentUser;
  }

  public get currentUserEmail() {
    return this._currentUser?.email ?? null;
  }

  public get isUserPasswordChanged() {
    return this._currentUser?.displayName === 'true';
  }

  public get isUserAdmin() {
    return this._isCurrentUserAdmin;
  }

  public get isUserPanasonicAdmin() {
    return this._isCurrentUserPanasonicAdmin;
  }

  public get currentUserData() {
    return this._userAdditionalData;
  }

  public get didUserPlacedOrder() {
    return this.userOrdersService.didUserPlaceOrder;
  }

  constructor(
    private mongoService: MongoService,
    private adminService: AdministratorManagementService,
    private userOrdersService: UserOrdersService,
    private scoringService: ScoringService,
    private navigator: NavigatorService,
    private cartService: CartService,
    private rankingService: RankingService,
    private apiService: ApiService,
  ){ }

  public async initialize() : Promise<void> {
    onAuthStateChanged(getAuth(), async (user) => {
      this._currentUser = user;
      if (this._currentUser) {
        await this.establishIfUserIsAdmin();
        let userData = await this.getUserDataByEmail(this._currentUser?.email ?? '');
        this.setUserAdditionalData(userData);
        await this.establishIfUserPlacedOrder();
      } else {
        this._isCurrentUserAdmin = false;
        this.setUserAdditionalData(null);
      }
    });
    await this.signOut(false);
  }

  private setUserAdditionalData(userAdditionalData: any) : void {

    this._userAdditionalData = userAdditionalData;
    this.cartService.setUserPointsAmount(this._userAdditionalData?.prizeAmount ?? 0);
  }

  public async signOut(redirectAfterLogout: boolean): Promise<void> {
    await signOut(getAuth());
    this._currentUser = null;
    this._isCurrentUserAdmin = false;
    this.cartService.resetCartState();
    if (redirectAfterLogout) {
      this.navigator.redirectToLoginScreen();
    }
  }

  public async login(email: string, password: string) : Promise<boolean> {
    try {
      await signInWithEmailAndPassword(getAuth(), email, password);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  public async changeCurrentUserPassword(newPassword: string) : Promise<boolean> {
    const passwordChangeResult = await this.changePassword(newPassword);
    if (passwordChangeResult) {
      const profileUpdateResult = await this.markPasswordAsChanged();
      return profileUpdateResult;
    }
    return false;
  }

  public async resetUserPassword(email: string) : Promise<boolean> {
    const passwordResetResult = await this.resetPassword(email);
    if (passwordResetResult)
    return true;
    else return false;
  }

  public async changeCurrentUserInitialData(userEmail: string, firstName: string, lastName: string,
      companyName: string, street: string, number: string,
      postalCode: string, city: string, voivodeship: string, phoneNumber: string) : Promise<boolean> {
    
      let userData = await this.mongoService.getUserDataByEmail(userEmail);
      if(userData){
        userData.userEmail = userEmail;
        userData.companyName = companyName;
        userData.firstName = firstName;
        userData.lastName = lastName;
        userData.phoneNumber = phoneNumber;
        userData.streetName = street;
        userData.buildingNumber = number;
        userData.postalCode = postalCode;
        userData.city = city;
        userData.voivodeship = voivodeship;
        userData.registered = true;
        await this.mongoService.updateUserData(userData);
        //await this.rankingService.prepareRankng();
        return true;
      }
    return false;
  }

  private async changePassword(newPassword: string) : Promise<boolean> {
    if (this.currentUser) {
      try {
        await updatePassword(this.currentUser, newPassword);
        return true;
      } catch (e) {
        console.log(e);
      }
    }
    return false;
  }

  private async resetPassword(email: string) : Promise<boolean> {
    const actionCodeSettings = {
      url: 'https://panasonic-store.pl/login',
      handleCodeInApp: false,
      dynamicLinkDomain: 'ex11.page.link',
    };
    try {
      await sendPasswordResetEmail(getAuth(), email, actionCodeSettings);
      return true;
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  private async markPasswordAsChanged() : Promise<boolean> {
    if (this.currentUser) {
      try {
        await updateProfile(this.currentUser, { displayName: 'true' });
        return true;
      } catch(e) {
        console.log(e);
      }
    }
    return false;
  }

  private async establishIfUserIsAdmin() : Promise<void> {
    if (this._currentUser) {
      let adminData = await this.adminService.getAdminData(this._currentUser.uid);
      if(adminData?.type === "advertusAdmin"){
        this._isCurrentUserAdmin = true;
      } else if (adminData?.type === "panasonicAdmin"){
        this._isCurrentUserPanasonicAdmin = true;
      } else {
        this._isCurrentUserAdmin = false;
        this._isCurrentUserPanasonicAdmin = false;
      }
      // this._isCurrentUserAdmin = await this.adminService.checkIfUserIdAdmin(this._currentUser);
    } else {
      this._isCurrentUserAdmin = false;
      this._isCurrentUserPanasonicAdmin = false;
    }
  }

  private async establishIfUserPlacedOrder() : Promise<void> {
    if (this._currentUser) {
      await this.userOrdersService.checkIfUserPlacedAnyOrder(this._currentUser);
    }
  }

  //////////////////////////////////////////////// MongoDB userData //////////////////////////////////////////////////////////
  private _currentMongoUsersDataList: Array<any> = [];
  private _scoringList: Array<any> = [];
  private _arrayForFirebase: Array<any> = [];

  public async updateUserDataCollection(newUsersData: any[]): Promise<boolean>{
    //pobierz istniejącą kolekcję userData
    this._currentMongoUsersDataList = await this.getAllExistingUsersData();
    //pobierz istniejącą kolekcję score
    this._scoringList = await this.scoringService.getAllExistingScores();

    //iteruj po danych z .xlsx
    for (const data of newUsersData){
      let userData = await this.findUserByEmail(data.email); //sprawdź czy istnieje w aktualnej liscie userData
      if(!userData){    //jeżeli nie istnieje w _currentMongoUsersDataList //utwórz nowy obiekt userData
        userData = await this.createNewUserDataObject(data);
        this._arrayForFirebase.push({userEmail: userData.userEmail, temporaryPassword: "%TGB7ujm"});
      }
      //zidentyfikuj ile punktów jest przypisane do urządzenia
      //dla użytkownika utwórz obiekt registeredProduct
      userData.registeredProducts = await this.createRegisteredProducts(userData, data);
      //dodaj do listy użytkowników
      this._currentMongoUsersDataList = await this.updateOrAddObject(this._currentMongoUsersDataList, userData);
    }    
    
    //wyczyść kolekcję w mongo
    await this.mongoService.deleteUsersData();
    // dodaj dane z _currentMongoUsersDataList
    // await this.mongoService.addUsersData(this._currentMongoUsersDataList);    
    const [tab1, tab2] = await this.splitTable(this._currentMongoUsersDataList);

    const [tab11, tab12] = await this.splitTable(tab1);
    const [tab21, tab22] = await this.splitTable(tab2);

    const [tab111, tab112] = await this.splitTable(tab11);
    const [tab121, tab122] = await this.splitTable(tab12);
    const [tab211, tab212] = await this.splitTable(tab21);
    const [tab221, tab222] = await this.splitTable(tab22);

    await this.mongoService.addUsersData(tab111); 
    await this.mongoService.addUsersData(tab112); 
    await this.mongoService.addUsersData(tab121); 
    await this.mongoService.addUsersData(tab122); 
    await this.mongoService.addUsersData(tab211); 
    await this.mongoService.addUsersData(tab212); 
    await this.mongoService.addUsersData(tab221); 
    await this.mongoService.addUsersData(tab222); 
    //jeżeli użytkownicy wcześniej nie istnieli w Firebase - utwórz
    console.log("this._arrayForFirebase ", this._arrayForFirebase);
    await this.apiService.addUsers(this._arrayForFirebase); //utwórz nowego użytkownika w Firebase
    //wyślij mailing powitalny
    await this.apiService.sendHelloEmails(this._arrayForFirebase);
    //utwórz ranking
    await this.rankingService.prepareRankng();

    this._currentMongoUsersDataList = [];
    this._scoringList = [];
    this._arrayForFirebase = [];
    
    return true;
  }

  async splitTable(tablica:Array<any>) {
    const middleIndex = Math.floor(tablica.length / 2);
    
    const firstHalf = tablica.slice(0, middleIndex);
    const secondHalf = tablica.slice(middleIndex);
  
    return [firstHalf, secondHalf];
  }

  public async getAllExistingUsersData(): Promise<Array<any>>{
      return await this.fetchUsersData();
  }

  private async fetchUsersData() : Promise<Array<any>> {
    try {
      const data = await this.mongoService.getAllUsersData();
      return data;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  public async getUserDataByEmail(userEmail: string) : Promise<any> {
    try {
      return await this.mongoService.getUserDataByEmail(userEmail);
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  checkUserExistsInUsersDataCollection(userEmail:string):boolean{
    if(this._currentMongoUsersDataList && this._currentMongoUsersDataList.length > 0){
      return this._currentMongoUsersDataList.some(itemB => itemB.userEmail === userEmail);
    } else return false;
  }

  async findUserByEmail(userEmail: string): Promise<any> {
    if(this._currentMongoUsersDataList && this._currentMongoUsersDataList.length > 0){
      return this._currentMongoUsersDataList.find(userData => userData.userEmail.toLowerCase() === userEmail.toLowerCase());
    } else return null;
  }

  private async createNewUserDataObject(data:any): Promise<any>{
    return {
      userEmail: data.email.toLowerCase(),
      buildingNumber: "",
      city: "",
      voivodeship: "",
      companyAddress: "",
      companyName: "",
      nipCode: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      points: 0,
      postalCode: "",
      streetName: "",
      // qualified: false,
      prizeAmount: 0,
      registered: false,
      registeredProducts :[]
    };
  }

  private async createRegisteredProducts(userData:any, data:any){
    let tabA = userData.registeredProducts;
    let tabB = await this.createRegisteredProductsObjects(data);
    return await this.addNonExistingElements(tabA, tabB);
  }

  private async createRegisteredProductsObjects(data: any): Promise<Array<any>>{
    let resultArr: any[] = []
    let productArr = data.product.split(',');
    productArr.forEach((elem:any) => {
      const cleanedString = elem.replace(/\s+/g, ' ').trim();
      if(!cleanedString.split(" ")[0] && !(cleanedString.split(" ")[0].length > 0)){
        } else if (!cleanedString.split(" ")[1] && !(cleanedString.split(" ")[1].length > 0)){
        } else {
          resultArr.push({productName: cleanedString.split(" ")[0], productSerial: cleanedString.split(" ")[1], score: 0, date: data.date})
        }
    });
    let registeredProducts = await this.checkScore(resultArr, this._scoringList);
    return registeredProducts;
  }

  private async addNonExistingElements(tabA:any, tabB:any) {
    if(tabB && tabB && tabB.length > 0){
      const existingSerialCodes = tabA.map((item:any) => item.productSerial);

      const newElements = tabB.filter((item:any) => 
        !existingSerialCodes.includes(item.productSerial)
      );
  
      tabA = tabA.concat(newElements);
      return tabA;
    }
  }

  private async checkScore(resultArr: any[], scoringList: any[]): Promise<any[]> {
    let returnArr = resultArr;
    for (const prod of resultArr) {
      let scoreObj = scoringList.find(product => product.productName === prod.productName);
      if(scoreObj){
        prod.score = scoreObj.score;
      } else prod.score = 0;
    }
    return returnArr;
  }

  private async countRegisteredProductsScore(userData: any){
    return userData.registeredProducts.reduce((sum:any, product:any) => sum + product.score, 0);
  }

  private async updateOrAddObject(list:any, objectToUpdate:any): Promise<Array<any>> {
    let found = false;
    const updatedList = list.map((item:any) => {
        if (item.userEmail === objectToUpdate.userEmail) {
            found = true;
            return objectToUpdate;
        }
        return item;
    });

    if (!found) {
        updatedList.push(objectToUpdate);
    }
    return updatedList;
  }

  async getUserRankingAndCount(currentUserData: any) {
    const userData = await this.getAllExistingUsersData();
    const sortedUsers = [...userData].sort((a, b) => b.points - a.points);
    const user = sortedUsers.find(u => u.userEmail === currentUserData.userEmail);

    if (!user) {
      console.error("getUserRankingAndCount User not found");
      return;
    }

    let rankingVar = 1;
    const pointsMap = new Map();
    for (let i = 0; i < sortedUsers.length; i++) {
      const currentUser = sortedUsers[i];

      if (!pointsMap.has(currentUser.points)) {
        pointsMap.set(currentUser.points, rankingVar);
        rankingVar++;
      }
    }
    let ranking = pointsMap.get(currentUserData.points);
    const samePointsCount = sortedUsers.filter(u => u.points === user.points).length;

    return { ranking, samePointsCount };
  }
}
