<div class="application-view-main-container">
    <div class="application-view-title">Administracja</div>
    <div class="buttons-container">
        <button type="button" class="btn btn-third-custom btn-third-option" (click)="redirecTo('usersManagement')">Zarządzanie użytkownikami</button>
        <button type="button" class="btn btn-third-custom btn-third-option" (click)="redirecTo('scoring')">Punktacja</button>
        <button type="button" class="btn btn-third-custom btn-third-option" (click)="redirecTo('prizes')">Nagrody</button>
        <button *ngIf="this.userDataService.isUserAdmin" type="button" class="btn btn-third-custom btn-third-option" (click)="redirecTo('databaseManagement')">Zasoby bazy danych</button>
        <button *ngIf="this.userDataService.isUserAdmin" type="button" class="btn btn-third-custom btn-third-option" (click)="redirecTo('emiails')">Wiadomości email</button>
        <button *ngIf="this.userDataService.isUserAdmin" type="button" class="btn btn-third-custom btn-third-option" (click)="redirecTo('promoCampaign')">Ustawienia akcji promocyjnej</button>
        <button type="button" class="btn btn-third-custom btn-third-option" (click)="redirecTo('reports')">Raporty</button>
    </div>
</div>