import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { PasswordRequirementsDialogComponent } from '../common/password-requirements-dialog/password-requirements-dialog.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  public resetPasswordForm: UntypedFormGroup = this.buildPasswordResetForm();
  public formSubmitted: boolean = false;
  public invalidAuthenticationData: boolean = false;
  public isSpinnerVisible: boolean = false;
  public passwordResetResult: any;

  constructor(
    private userDataService: UserDataService,
    private apiService: ApiService,
    private dialog: MatDialog,    
    private formValidation: FormValidationService,
    private formBuilder: UntypedFormBuilder) { 
      this.resetPasswordForm = this.buildPasswordResetForm();
    }

  ngOnInit(): void {
  }

  private buildPasswordResetForm(): UntypedFormGroup {
    return this.formBuilder.group({
      email: ['', this.formValidation.trimmedRequiredValidator()]
    });
  }

  public openPasswordRequirementsDialog() : void {
    this.dialog.open(PasswordRequirementsDialogComponent);
  }

  public confirm(): void {
    if (!this.resetPasswordForm.invalid) {
      this.sendResetRequest();
    }
  }

  private async sendResetRequest(): Promise<void> {
    this.isSpinnerVisible = true;
    
    //Przez API    
    // const templateData = { emailAddress: String(this.resetPasswordForm.controls.email.value).toString() }
    // const resetResult = await this.apiService.sendPasswordResetEmail(templateData);
    
    //Bezpośrednio
    await this.userDataService.resetUserPassword(String(this.resetPasswordForm.controls.email.value).toString()).then(value => {
      this.passwordResetResult = value;
      this.formSubmitted = true;
    })
    this.isSpinnerVisible = false;
  }
}
