import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.css']
})
export class YesNoDialogComponent {

  public title: string = '';
  public message: string = '';
  private confirmFunction: (() => void) | null = null;

  constructor(private dialogReference: MatDialogRef<YesNoDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public config: any) {
    this.title = config?.title ?? '';
    this.message = config?.message ?? '';
    this.confirmFunction = config?.confirmFunction ?? null;
  }

  public cancel() : void {
    this.dialogReference.close();
  }

  public confirm() : void {
    if (this.confirmFunction) {
      this.confirmFunction();
    }
    this.cancel();
  }
}
