<div class="base-dialog-container" [ngStyle]="{ 'height.rem' : height, 'width.rem': width }">
  <div class="dialog-header" [ngClass]="{ 'dialog-header-error' : isError }">
    <ng-content select="[header-slot]"></ng-content>
  </div>
  <div class="dialog-body-footer-container">
    <div class="dialog-body-container">
      <ng-content select="[content-slot]"></ng-content>
    </div>
    <div class="dialog-footer-container">
      <ng-content select="[footer-slot]"></ng-content>
    </div>
  </div>
</div>