import { Injectable } from '@angular/core';
import { BenefitsService } from '../services/benefits.service';
import { UserOrdersService } from '../services/user-orders.service';
import { ExcelReaderService } from './excel-reader.service';

@Injectable({
  providedIn: 'root'
})
export class BenefitsLoaderService {

  constructor(private excelReader: ExcelReaderService,
    private userOrdersService: UserOrdersService,
    private benefitsService: BenefitsService) { }

  public async executeBenefitsReplacement(excelFile: any) : Promise<boolean> {
    try {
      const benefitsData = await this.excelReader.parseExcelFile(excelFile);
      const validBenefits = this.collectValidBenefitData(benefitsData);
      if (validBenefits.length > 0) {
        await this.userOrdersService.clearOrdersData();
        await this.benefitsService.clearBenefitsData();
        const successesAmount = await this.benefitsService.addBenefitsToStorage(validBenefits);
        return validBenefits.length === benefitsData.length &&
        successesAmount === validBenefits.length;
      } else {
        return true;
      }
    } catch (e) {
      console.error(e);
      console.error('Could not load new benefits data');
      return false;
    }
  }

  private collectValidBenefitData(excelBenefitsData: Array<any>) : Array<any> {
    const validBenefits: Array<any> = [];
    excelBenefitsData.forEach(bd => {
      const parsedBenefit = this.createBenefitRecordObject(bd);
      if (this.validateSingleBenefitRecord(parsedBenefit)) {
        validBenefits.push(parsedBenefit);
      }
    });
    return validBenefits;
  }

  private validateSingleBenefitRecord(benefitRecord: any) : boolean {
    if (benefitRecord?.availablePrices?.length > 0 &&
        benefitRecord?.description?.length > 0 &&
        benefitRecord?.imageUrl?.length > 0 &&
        benefitRecord?.name?.length > 0 &&
        benefitRecord?.validityPeriodMonths > 0 &&
        benefitRecord?.displayOrder > 0) {
      return true;
    }
    console.warn('Invalid benefit data.');
    console.warn(benefitRecord);
    return false;
  }

  private createBenefitRecordObject(benefitExcelData: any) : any {
    const benefitData: any = {
      availablePrices: this.parseAvailablePrices(benefitExcelData.availablePrices),
      description: benefitExcelData.description,
      hyperlink: benefitExcelData.hyperlink,
      imageUrl: benefitExcelData.imageUrl,
      name: benefitExcelData.benefitName,
      validityPeriodMonths: benefitExcelData.validityMonths,
      displayOrder: benefitExcelData.displayOrder
    };
    return benefitData;
  }

  private parseAvailablePrices(pricesString: string) : Array<number> {
    try {
      const pricesArray = pricesString.split(';');
      const prices: Array<number> = [];
      pricesArray.forEach(p => prices.push(+p));
      return prices;
    } catch (e) {
      console.error(e);
      return [];
    }
  }
}
