import { Component } from '@angular/core';
import { NavigatorService } from 'src/app/services/navigator.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'administration-view',
  templateUrl: './administration-view.component.html',
  styleUrl: './administration-view.component.css'
})

export class AdministrationViewComponent {

  constructor(
    private navigatorService: NavigatorService,
    public userDataService: UserDataService,
    public location: Location,
    public router: Router
  ){ }

  redirecTo(moduleName: string){
    switch (moduleName) {
      case 'usersManagement':
        this.navigatorService.redirectToUsersManagementScreen();
        break;
      case 'scoring':
        this.navigatorService.redirectToScoringScreen();
        break;
      case 'prizes':
        this.navigatorService.redirectToPrizesScreen();
        break;
      case 'databaseManagement':
        this.navigatorService.redirectToDatabaseManagementScreen();
        break;
      case 'emiails':
        this.navigatorService.redirectToEmailMessagesScreen();
        break;
      case 'reports':
        this.navigatorService.redirectToAdminReportsScreen();
        break;
      case 'promoCampaign':
        this.navigatorService.redirectToPromoCampaignManagementScreen();
        break;    
      default:
        break;
    }
  }
}
