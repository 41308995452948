import { Component, Input } from '@angular/core';

@Component({
  selector: 'dialog-base',
  templateUrl: './dialog-base.component.html',
  styleUrls: ['./dialog-base.component.css'],
})
export class DialogBaseComponent {
  @Input() width: number = 20;
  @Input() height: number = 30;
  @Input() isError: boolean = false;

  constructor() {}
}
