<div class="login-form-container">
  <form [formGroup]="passwordChangeForm" (ngSubmit)="confirm()">
    <div class="login-form-items-container">
      <div class="login-form-title-container">
        <span>Zmiana hasła</span>
      </div>
      <div class="login-form-input-description-container">
        <span>Adres email</span>
        <input type="email" class="form-control" [value]="currentUserEmailAddress" disabled/>
      </div>
      <div class="login-form-input-description-container">
        <span>Wprowadź nowe hasło</span>
        <input type="password" class="form-control" formControlName="password"/>
        <span *ngIf="formSubmitted && passwordControls.password.errors"
          class="input-error">Hasło musi być podane oraz musi spełniać wymagania</span>
      </div>
      <div class="login-form-input-description-container">
        <span>Powtórz nowe hasło</span>
        <input type="password" class="form-control" formControlName="password2"/>
        <span *ngIf="formSubmitted && passwordControls.password2.errors"
          class="input-error">Hasło musi być podane oraz musi spełniać wymagania</span>
      </div>
      <div *ngIf="formSubmitted && passwordChangeForm.errors">
        <span class="input-error">Podane hasła nie są sobie równe</span>
      </div>
      <div class="password-requirements-button-container">
        <button type="button" class="btn btn-secondary btn-secondary-custom"
          (click)="openPasswordRequirementsDialog()">Wymagania hasła</button>
      </div>
      <div class="login-dorm-error-info-container"
        *ngIf="passwordChangeRequestFailed">
        <span>Nie udało się zmienić hasła</span>
      </div>
      <div class="login-form-submit-button-container">
        <button class="btn btn-primary btn-primary-custom">Zmień</button>
      </div>
    </div>
  </form>
</div>
<waiting-spinner *ngIf="isSpinnerVisible" [text]="'Przetwarzanie...'"></waiting-spinner>