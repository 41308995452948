import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { PasswordRequirementsDialogComponent } from '../common/password-requirements-dialog/password-requirements-dialog.component';

@Component({
  selector: 'app-initial-data',
  templateUrl: './initial-data.component.html',
  styleUrls: ['./initial-data.component.css']
})
export class InitialDataComponent {

  public intialDataForm: UntypedFormGroup = this.buildInitialDataForm();
  public formSubmitted: boolean = false;
  public dataUpdateFailed: boolean = false;
  public isSpinnerVisible: boolean = false;
  public termsCheckbox: boolean = false;

  public get formControls() {
    return this.intialDataForm.controls;
  }

  public get currentUserEmailAddress() {
    return this.userDataService.currentUserEmail ?? '';
  }

  constructor(
    private userDataService: UserDataService,
    private dialog: MatDialog,
    private navigator: NavigatorService,
    private formBuilder: UntypedFormBuilder,
    private formValidation: FormValidationService) {
      this.intialDataForm = this.buildInitialDataForm();
  }

  public async confirm() : Promise<void> {
    this.formSubmitted = true;
    if (!this.intialDataForm.invalid) {
      await this.executeDataUpdate();
    }
  }

  public openPasswordRequirementsDialog() : void {
    this.dialog.open(PasswordRequirementsDialogComponent);
  }

  private buildInitialDataForm() : UntypedFormGroup {
    return this.formBuilder.group({
      firstName: ['', this.formValidation.trimmedRequiredValidator()],
      lastName: ['', this.formValidation.trimmedRequiredValidator()],
      companyName: ['', this.formValidation.trimmedRequiredValidator()],
      phoneNumber: ['', this.formValidation.trimmedRequiredValidator(8)],
      street: [''],
      number: ['', this.formValidation.trimmedRequiredValidator()],
      postalCode: ['', this.formValidation.trimmedRequiredValidator()],
      city: ['', this.formValidation.trimmedRequiredValidator()],
      voivodeship: ['', this.formValidation.trimmedRequiredValidator()],
      password: ['', this.formValidation.passwordValidator()],
      password2: ['', this.formValidation.passwordValidator()]
    }, { validators: this.formValidation.passwordMatchValidator('password', 'password2')});
  }

  private async executeDataUpdate() : Promise<void> {
    this.isSpinnerVisible = true;
    try {
      let result: boolean = await this.userDataService.changeCurrentUserPassword(this.formControls.password.value);
      if (result) {
        result = await this.userDataService.changeCurrentUserInitialData(
          this.userDataService.currentUserEmail ?? '',
          this.formControls.firstName.value ?? '',
          this.formControls.lastName.value ?? '',
          this.formControls.companyName.value ?? '',
          this.formControls.street.value ?? '',
          this.formControls.number.value ?? '',
          this.formControls.postalCode.value ?? '',
          this.formControls.city.value ?? '',
          this.formControls.voivodeship.value ?? '',
          this.formControls.phoneNumber.value ?? ''
        );
      }
      if (result) {
        this.redirectAfterSuccess();
      } else {
        this.displayErrorInfo();
      }
    } catch (e) {
      console.warn(e);
      this.displayErrorInfo();
    }
    this.isSpinnerVisible = false;
  }

  private redirectAfterSuccess() : void {
    this.navigator.redirectToLandingPage();
  }

  private displayErrorInfo() : void {
    this.formSubmitted = false;
    this.dataUpdateFailed = true;
    setTimeout(() => {
      this.dataUpdateFailed = false;
    }, 5000);
  }

  downloadPdf(){
    const pdfUrl = 'assets/regulamin_promocji_aquarea.pdf'; // Ścieżka do pliku PDF w katalogu assets
    const a = document.createElement('a');
    a.href = pdfUrl;
    a.download = 'regulamin_promocji_aquarea.pdf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  toggleTermsCheckbox(event: Event){
    const checked = (event.target as HTMLInputElement).checked;
    this.termsCheckbox = checked;
  }
}
