import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { NavigatorService } from './services/navigator.service';
import { UserDataService } from './services/user-data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(
    private userDataService: UserDataService,
    private navigator: NavigatorService)
  { }

  canActivate(
    route: ActivatedRouteSnapshot //od wersji 15 musi miec właściwość "title"
  ): boolean {
    if (route.routeConfig?.path === NavigatorService.landingPagePath) {
      return true;
    }
    if (route.routeConfig?.path === NavigatorService.contactsScreenPath) {
      return true;
    }
    if (route.routeConfig?.path?.includes(NavigatorService.passwordResetScreenPath) &&
        !this.userDataService.isUserLoggedIn) {
      return true;
    }
    if (route.routeConfig?.path?.includes(NavigatorService.initialDataScreenPath) && 
      !this.userDataService.isUserLoggedIn) {
        this.navigator.redirectToLoginScreen();
        return false;
      }
    if (this.userDataService.isUserLoggedIn !== true) {
      this.navigator.redirectToLoginScreen();
      return false;
    }
    if (route.routeConfig?.path?.includes(NavigatorService.passwordResetScreenPath) &&
        this.userDataService.isUserLoggedIn) {
      this.navigator.redirectToBenefitsScreen();
      return false;
    }
    if (route.routeConfig?.path === NavigatorService.benefitsScreenPath &&
        !this.userDataService.isUserPasswordChanged) {
      this.navigator.redirectToInitialDataScreen();
      return false;
    }
    if (route.routeConfig?.path === NavigatorService.benefitDetailsScreenPath &&
        !this.userDataService.isUserPasswordChanged) {
      this.navigator.redirectToInitialDataScreen();
      return false;
    }
    if (route.routeConfig?.path === NavigatorService.cartScreenPath &&
        !this.userDataService.isUserPasswordChanged) {
      this.navigator.redirectToInitialDataScreen();
      return false;
    }

    //ADMINISTRACJA
    if (route.routeConfig?.path?.includes(NavigatorService.administrationScreenPath) &&
      (!this.userDataService.isUserAdmin && !this.userDataService.isUserPanasonicAdmin)) {
      this.navigator.redirectToBenefitsScreen();
      return false;
    }
    if (route.routeConfig?.path?.includes(NavigatorService.usersManagementcreenPath) &&
      (!this.userDataService.isUserAdmin && !this.userDataService.isUserPanasonicAdmin)) {
      this.navigator.redirectToBenefitsScreen();
      return false;
    }
    if (route.routeConfig?.path?.includes(NavigatorService.scoringScreenPath) &&
      (!this.userDataService.isUserAdmin && !this.userDataService.isUserPanasonicAdmin)) {
      this.navigator.redirectToBenefitsScreen();
      return false;
    }
    if (route.routeConfig?.path?.includes(NavigatorService.databaseManagementScreenPath) &&
      !this.userDataService.isUserAdmin) {
      this.navigator.redirectToBenefitsScreen();
      return false;
    }
    if (route.routeConfig?.path?.includes(NavigatorService.emailMessagesScreenPath) &&
      !this.userDataService.isUserAdmin) {
      this.navigator.redirectToBenefitsScreen();
      return false;
    }
    if (route.routeConfig?.path?.includes(NavigatorService.adminReportsScreenPath) &&
        !this.userDataService.isUserAdmin && !this.userDataService.isUserPanasonicAdmin) {
      this.navigator.redirectToBenefitsScreen();
      return false;
    }
    if (route.routeConfig?.path?.includes(NavigatorService.promoCampaignManagementPath) &&
      !this.userDataService.isUserAdmin) {
      this.navigator.redirectToBenefitsScreen();
      return false;
    }
    if (route.routeConfig?.path?.includes(NavigatorService.adminDataScreenPath) &&
        !this.userDataService.isUserAdmin) {
      this.navigator.redirectToBenefitsScreen();
      return false;
    }

    //UŻYTKOWNIK
    if (route.routeConfig?.path?.includes(NavigatorService.userPath) && 
    !this.userDataService.isUserLoggedIn) {
      this.navigator.redirectToLoginScreen();
      return false;
    }
    if (route.routeConfig?.path?.includes(NavigatorService.userDetailsPath) && 
    !this.userDataService.isUserLoggedIn) {
      this.navigator.redirectToLoginScreen();
      return false;
    }
    if (route.routeConfig?.path?.includes(NavigatorService.passwordChangeScreenPath) && 
    !this.userDataService.isUserLoggedIn) {
      this.navigator.redirectToLoginScreen();
      return false;
    }
    return true;
  }
}
