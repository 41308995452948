<dialog-base [width]="25" [height]="20" [isError]="false">
  <div header-slot>{{ title }}</div>
  <div content-slot>
    <span *ngFor="let text of passwordRulesText" class="text-span">{{ text }}</span>
  </div>
  <div footer-slot class="dialog-buttons-container">
    <button type="button" class="btn btn-primary btn-primary-custom" (click)="close()">
      OK
    </button>
  </div>
</dialog-base>