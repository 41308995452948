import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { NavigatorService } from 'src/app/services/navigator.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup = this.buildLoginForm();
  public formSubmitted: boolean = false;
  public invalidAuthenticationData: boolean = false;
  public isSpinnerVisible: boolean = false;

  public get loginControls() {
    return this.loginForm?.controls;
  }

  constructor(
    private formValidation: FormValidationService,
    private formBuilder: UntypedFormBuilder,
    private navigator: NavigatorService,
    private userDataService: UserDataService
  ) {
    this.loginForm = this.buildLoginForm();
  }

  public ngOnInit(): void {
    if (this.userDataService.isUserLoggedIn) {
      this.redirectSuccessFulLogin();
    }
  }

  public resetPassword(): void {
    this.navigator.redirectToPasswordResetScreen();
  }

  public confirm(): void {
    this.formSubmitted = true;
    if (!this.loginForm.invalid) {
      this.sendLoginRequest();
    }
  }

  private buildLoginForm(): UntypedFormGroup {
    return this.formBuilder.group({
      email: ['', this.formValidation.trimmedRequiredValidator()],
      password: ['', this.formValidation.trimmedRequiredValidator()],
    });
  }

  private async sendLoginRequest(): Promise<void> {
    this.isSpinnerVisible = true;
    const loginResult = await this.userDataService.login(
      this.loginControls.email.value,
      this.loginControls.password.value
    );
    this.isSpinnerVisible = false;
    if (loginResult === true) {
      this.redirectSuccessFulLogin();
    } else {
      this.setInvalidAuthenticationDataInfoVisible();
    }
  }

  private setInvalidAuthenticationDataInfoVisible() : void {
    this.loginControls.email.patchValue('');
    this.loginControls.password.patchValue('');
    this.invalidAuthenticationData = true;
    setTimeout(() => {
      this.invalidAuthenticationData = false;
    }, 5000);
  }

  private redirectSuccessFulLogin() : void {
    if (this.userDataService.isUserPasswordChanged) {
      this.navigator.redirectToLandingPage();
    } else {
      this.navigator.redirectToInitialDataScreen();
    }
  }
}
