import { Injectable } from '@angular/core';
import { ExcelReaderService } from '../dataExchange/excel-reader.service';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class MailingService {

  constructor(private excelReader: ExcelReaderService, private apiService: ApiService) { }

  public async sendHelloMailing(excelFile: any) : Promise<boolean> {
    try {
      const helloEmailsData = await this.excelReader.parseExcelFile(excelFile);
      
      const validEmailsData = this.collectValidEmailsData(helloEmailsData);
      if (validEmailsData.length > 0) {
        //console.log("validEmailsData ", validEmailsData);
        await this.apiService.sendHelloEmails(validEmailsData);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
      console.error('Error during sending emails');
      return false;
    }
  }

  private collectValidEmailsData(excelEmailsData: Array<any>) : Array<any> {
    const validEmailsData: Array<any> = [];
    excelEmailsData.forEach(u => {
      if (u?.userEmail?.length > 0 && u?.temporaryPassword?.length >= 8) {
        validEmailsData.push(u);
      }
    });
    return validEmailsData;
  }
}
