import { Component, OnInit } from '@angular/core';
import { BenefitsService } from 'src/app/services/benefits.service';
import { CartService } from 'src/app/services/cart.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { AlertService } from '../../common/alert/alert.service';
import { PromoCampaignService } from 'src/app/services/promo-campaign.service';

@Component({
  selector: 'app-benefits-view',
  templateUrl: './benefits-view.component.html',
  styleUrls: ['./benefits-view.component.css']
})
export class BenefitsViewComponent implements OnInit {

  public benefitsSource: Array<any> = [];
  public isSpinnerVisible: boolean = false;

  public get userPointsAmount () {
    return this.cartService?.userAvailablePoints ?? 0;
  }

  public get didUserPlaceOrder() {
    return this.userDataService.didUserPlacedOrder;
  }
  
  constructor(
    private benefitsService: BenefitsService,
    private cartService: CartService,
    private userDataService: UserDataService,
    public promoCampaignService: PromoCampaignService,
    private alertService: AlertService
  )
  { }

  async ngOnInit(): Promise<void> {
    this.isSpinnerVisible = true;
    await this.fetchBenefits();
    this.isSpinnerVisible = false;
    if(this.promoCampaignService.usersMessageActive){
      this.alertService.error(this.promoCampaignService.userMessage, {alias: "test", autoClose: false, keepAfterRouteChange: true, priority: 1});
    }
  }

  private async fetchBenefits() : Promise<void> {
    this.benefitsSource = await this.benefitsService.getAllExistingBenefits();
  }
}
