import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormValidationService {
  public static PASSWORD_SPECIAL_CHARACTERS: Array<string> = [
    '!',
    '@',
    '#',
    '$',
    '%',
    '^',
    '&',
    '*',
    '(',
    ')',
    '-',
    '=',
    '+',
    '[',
    ']',
    '{',
    '}',
    ':',
    ';',
    '?',
    '<',
    '>',
  ];

  public trimmedRequiredValidator(length: number = 0): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      let currentValue = control.value.trim();
      currentValue = currentValue.replace(' ', '');
      return currentValue.length > length ? null : { result: 'invalid' };
    }
  }

  public emailAddressValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isEmailValid = regex.test(control.value.trim().toLowerCase());
      return isEmailValid ? null : { result: 'invalid' };
    };
  }

  public passwordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const password = control.value;
      const allRequirementsMatched =
        this.validateAgainstLength(password) &&
        this.vaidateAgainstUpperCase(password) &&
        this.validateAgainstLowerCase(password) &&
        this.validateAgainstDigit(password) &&
        this.vlidateAgainstSpecialCharacter(password);
      return allRequirementsMatched ? null : { result: 'invalid' };
    };
  }

  public passwordMatchValidator(firstPasswordInputName: string,
      secondPasswordInputName: string) : ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        const firstPassword = control?.get(firstPasswordInputName)?.value;
        const secondPassword = control?.get(secondPasswordInputName)?.value;
        return firstPassword === secondPassword ? null : { result: 'invalid'};
    }
  }

  private validateAgainstLength(string: string): boolean {
    return string.length >= 8;
  }

  private vaidateAgainstUpperCase(string: string): boolean {
    return string.toLowerCase() !== string;
  }

  private validateAgainstLowerCase(string: string): boolean {
    return string.toUpperCase() !== string;
  }

  private validateAgainstDigit(string: string): boolean {
    return /\d/.test(string);
  }

  private vlidateAgainstSpecialCharacter(string: string): boolean {
    let found = false;
    FormValidationService.PASSWORD_SPECIAL_CHARACTERS.forEach((c) => {
      if (string.includes(c)) {
        found = true;
      }
    });
    return found;
  }
}
