<div class="icon-card-wrapper">
  <div class="icon-card-border-container icon-card-border-container-info">
    <div class="icon-card-icon-container"></div>
    <div class="report-elements-container">
      <div class="report-info-data-container">
        <span class="bolded-text app-card-text-underline">{{ reportTitle }}</span>
        <p class="report-description">{{ reportDescription }}</p>
      </div>
      <div class="generate-report-button-container">
        <button type="button" class="btn btn-sm btn-primary btn-primary-custom"
          (click)="handleGenerateReportButtonClick()">Generuj raport</button>
      </div>
    </div>
  </div>
</div>