import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormValidationService } from 'src/app/services/form-validation.service';

@Component({
  selector: 'app-password-requirements-dialog',
  templateUrl: './password-requirements-dialog.component.html',
  styleUrls: ['./password-requirements-dialog.component.css']
})
export class PasswordRequirementsDialogComponent implements OnInit {

  public title: string = 'Wymagania hasła';
  public passwordRulesText: Array<string> = [
    'Hasło musi spełniać poniższe wymagania:',
    '- co najmniej 8 znaków długości,',
    '- co najmniej jedna wielka litera,',
    '- co najmniej jedna mała litera,',
    '- co najmniej jedna cyfra,',
    '- co najmniej jeden znak spesjalny ze zbioru ' + this.concatinateSpecialCharacters() + '.'];

  constructor(private dialogReference: MatDialogRef<PasswordRequirementsDialogComponent>) { }

  ngOnInit(): void {
  }

  private concatinateSpecialCharacters() : string {
    return FormValidationService.PASSWORD_SPECIAL_CHARACTERS.join(' ');
  }

  public close() : void {
    this.dialogReference.close();
  }
}
