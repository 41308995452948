import { Injectable } from '@angular/core';
import { FirebaseInitService } from './firebase-init.service';
import { addDoc, collection, deleteDoc, doc, getDocs, query } from 'firebase/firestore';
import { MongoService } from './mongo.service';

@Injectable({
  providedIn: 'root'
})
export class BenefitsService {

  private benefitsCache: Array<any> = [];

  constructor(
    private firebaseService: FirebaseInitService,
    private mongoService: MongoService
  ) { }

  public async getAllExistingBenefits(ommitCache: boolean = false) : Promise<Array<any>> {
    if (ommitCache === false && this.benefitsCache?.length > 0) {
      return this.benefitsCache;
    } else {
      this.benefitsCache = await this.fetchBenefits();
      return this.benefitsCache;
    }
  }

  public async addBenefitsToStorage(benefitsData: Array<any>) : Promise<number> {
    let result: number = 0;
    for (let i = 0; i < benefitsData.length; i++) {
      if (await this.addSingleBenefitToStorage(benefitsData[i])) {
        result++;
      }
    }
    return result;
  }

  public async clearBenefitsData() : Promise<void> {
    const benefitsData = await this.getAllExistingBenefits(true);
    if (benefitsData?.length > 0) {
      for (let i = 0; i < benefitsData.length; i++) {
        await deleteDoc(doc(this.firebaseService.database, 'benefits', benefitsData[i]._id));
      }
    }
  }

  private async fetchBenefits() : Promise<Array<any>> {
    try {
      let data = await this.mongoService.getAllBenefitsData();
      const benefits: Array<any> = [];
      data.forEach((d:any) => {
        const data = d;
        benefits.push(data);
      });
      return this.orderBenefitsByDisplayOrder(benefits);
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  private orderBenefitsByName(benefits: Array<any>) : Array<any> {
    return benefits.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  private orderBenefitsByDisplayOrder(benefits: Array<any>) : Array<any> {
    return benefits.sort((a, b) => {
      if (a.displayOrder < b.displayOrder) {
        return -1;
      }
      if (a.displayOrder > b.displayOrder) {
        return 1;
      }
      return 0;
    });
  }

  private async addSingleBenefitToStorage(benefit: any): Promise<boolean> {
    try {
      // await addDoc(collection(this.firebaseService.database, 'benefits'), benefit); //todo zamienić na mongo
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}
