import { Component, OnInit, } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CartService } from 'src/app/services/cart.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { UserAdditionalDataService } from 'src/app/services/user-additional-data.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { UserOrdersService } from 'src/app/services/user-orders.service';
import { InfoDialogComponent } from '../../common/info-dialog/info-dialog.component';
import { YesNoDialogComponent } from '../../common/yes-no-dialog/yes-no-dialog.component';
import { PromoCampaignService } from 'src/app/services/promo-campaign.service';
import { AlertService } from '../../common/alert/alert.service';

@Component({
  selector: 'app-cart-view',
  templateUrl: './cart-view.component.html',
  styleUrls: ['./cart-view.component.css']
})
export class CartViewComponent implements OnInit {

  public isSpinnerVisible: boolean = false;

  public get cartItemsSource() {
    return this.cartService?.cartItems ?? [];
  }

  public get userAssignedPoints() {
    return this.cartService.userPoints;
  }

  public get userAvailablePoints() {
    return this.cartService.userAvailablePoints;
  }

  public get isPlaceOrderButtonEnabled() {
    if (this.userDataService.didUserPlacedOrder) {
      return false;
    }
    return this.cartService?.cartItems?.length > 0;
  }

  public get didUserPlaceOrder() {
    return this.userDataService.didUserPlacedOrder;
  }

  constructor(private cartService: CartService,
    private navigator: NavigatorService,
    private userOrdersService: UserOrdersService,
    private userDataService: UserDataService,
    private userAdditionalDataService: UserAdditionalDataService,
    private dialog: MatDialog,
    public promoCampaignService: PromoCampaignService,
    private alertService: AlertService
  ) {
  }

  async ngOnInit(): Promise<void> {
    if(this.promoCampaignService.usersMessageActive){
      this.alertService.error(this.promoCampaignService.userMessage, {alias: "test", autoClose: false, keepAfterRouteChange: true, priority: 1});
    }
  }

  public goToBenefitsCatalog() : void {
    this.navigator.redirectToBenefitsScreen();
  }

  public placeOrder() : void {
    this.dialog.open(YesNoDialogComponent, { disableClose: true, data: {
      title: 'Składanie zamówienia',
      message: 'Każdy użytkownik może złożyć zamówienie wyłącznie raz. ' +
        'Nie ma możliwości modyfikacji zamówienia, ani jego anulowania. ' +
        'Czy złożyć zamówienie z obecnym stanem koszyka?',
      confirmFunction: this.executePlaceOrder
    } });
  }

  private executePlaceOrder = async () : Promise<void> => {
    this.isSpinnerVisible = true;
    if (this.userDataService?.currentUser) {
      const orderPlaceResult = await this.userOrdersService
        .placeUserOrder(this.cartService, this.userDataService.currentUser, this.userDataService,
           this.userAdditionalDataService);
      if (orderPlaceResult) {
        this.handleSuccessfullOrderPlacement();
      } else {
        this.displayOrderPlacementErrorInfo();
      }
    } else {
      this.displayOrderPlacementErrorInfo();
    }
    this.isSpinnerVisible = false;
  }

  private displayOrderPlacementErrorInfo() : void {
    this.dialog.open(InfoDialogComponent, { disableClose: true, data: {
      title: 'Błąd',
      message: 'Nie udało się złożyć zamówienia. Spróbuj ponownie później.',
      isErrorInfo: true,
      okFunc: null
    } });
  }

  private handleSuccessfullOrderPlacement() : void {
    this.dialog.open(InfoDialogComponent, { disableClose: true, data: {
      title: 'Sukces',
      message: 'Twoje zamówienie zostało zarajestrowane. ' +
        'Na Twój adres email zostało wysłane potwierdzenie złożenia zamówienia. ' +
        'Od tej pory nie będziesz mieć możliwości złożenia kolejnego zamówienia.',
      isErrorInfo: false,
      okFunc: this.executeAfterOrderPlacedWork
    } });
  }

  private executeAfterOrderPlacedWork = () : void => {
    this.cartService.resetCartState();
    this.navigator.redirectToBenefitsScreen();
  }
}
