<dialog-base [width]="25" [height]="20" [isError]="isErrorInfo">
  <div header-slot>{{ title }}</div>
  <div content-slot>
    <div>{{ message }}</div>
  </div>
  <div footer-slot class="dialog-buttons-container">
    <button type="button" class="btn btn-primary btn-primary-custom" (click)="confirmMessage()">
      OK
    </button>
  </div>
</dialog-base>
